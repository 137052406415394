import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { flexRowCenterCenter } from "utils/styles";
import logo from "assets/icons/logo.svg";
import google from "assets/icons/google.svg";
import { BorderLinearProgress } from "pages/Insights/Components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { googleOnboarding } from "store/auth/userSlice";
import { AppDispatch } from "store";

const Exchange = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  useEffect(() => {
    const getQueryParam = (param: string) => {
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get(param);
    };
    const code = getQueryParam("code");
    const scope = getQueryParam("scope");
    dispatch(googleOnboarding({ code, scope, navigate }));
  }, []);

  return (
    <Box width="100vw" height="100vh" sx={{ ...flexRowCenterCenter }}>
      <Box>
        <Box sx={{ ...flexRowCenterCenter }}>
          <img src={logo} alt="MB Pro" height={50} />
          <Typography fontSize={42} sx={{ ml: 2 }}>
            My Business Pro
          </Typography>
        </Box>
        <Box
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            my: 5,
          }}
        >
          <Avatar
            sx={(theme) => ({
              bgcolor: theme.palette.grey["100"],
              width: 100,
              height: 100,
            })}
          >
            <img src={google} alt="google" height={50} />
          </Avatar>
          <Box m={7}>
            <Typography sx={{ pb: 7 }}>Authorization in progress...</Typography>
            <BorderLinearProgress
              value={70}
              variant="indeterminate"
              bgcolor="#a46592"
            />
          </Box>
          <Avatar
            sx={(theme) => ({
              bgcolor: theme.palette.grey["100"],
              width: 100,
              height: 100,
            })}
          >
            <img src={logo} alt="MB Pro" height={50} />
          </Avatar>
        </Box>
      </Box>
    </Box>
  );
};

export default Exchange;
