import {
  Box,
  Grid2,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { flexRowCenterCenter } from "utils/styles";
import google from "assets/icons/google.svg";
import CircularProgressWithBuffer from "components/CircularProgressWithBuffer";
import Select from "components/Select";

interface PerformanceData {
  location: string;
  profileViews: number;
  growth: number;
}

const performanceData: PerformanceData[] = [
  {
    location: "Nike Store T Nagar",
    profileViews: 120,
    growth: 87,
  },
  {
    location: "Nike Store Anna Nagar",
    profileViews: 20,
    growth: 98,
  },
  {
    location: "Nike Store Ambattur",
    profileViews: 34,
    growth: 78,
  },
  {
    location: "Nike Store VR Mall",
    profileViews: 46,
    growth: 84,
  },
  {
    location: "Nike Store Ambattur",
    profileViews: 89,
    growth: 91,
  },
  {
    location: "Nike Store Anna Nagar",
    profileViews: 143,
    growth: 78,
  },
  {
    location: "Nike Store Ambattur",
    profileViews: 90,
    growth: 91,
  },
  {
    location: "Nike Store VR Mall",
    profileViews: 45,
    growth: 78,
  },
  {
    location: "Nike Store Ambattur",
    profileViews: 87,
    growth: 10,
  },
  {
    location: "Nike Store Anna Nagar",
    profileViews: 47,
    growth: 78,
  },
  {
    location: "Nike Store Bangalore",
    profileViews: 150,
    growth: 67,
  },
  {
    location: "Nike Store Anna Nagar",
    profileViews: 20,
    growth: 98,
  },
  {
    location: "Nike Store Ambattur",
    profileViews: 34,
    growth: 78,
  },
  {
    location: "Nike Store VR Mall",
    profileViews: 46,
    growth: 84,
  },
  {
    location: "Nike Store Ambattur",
    profileViews: 89,
    growth: 91,
  },
  {
    location: "Nike Store Anna Nagar",
    profileViews: 165,
    growth: 78,
  },
  {
    location: "Nike Store Ambattur",
    profileViews: 90,
    growth: 91,
  },
  {
    location: "Nike Store VR Mall",
    profileViews: 45,
    growth: 78,
  },
  {
    location: "Nike Store",
    profileViews: 50,
    growth: 18,
  },
  {
    location: "Nike Store Anna Nagar",
    profileViews: 66,
    growth: 70,
  },
];

const performingShopsData = [
  {
    location: "Nike Store T Nagar",
    performance: "10,000 / 76%",
  },
  {
    location: "Nike showroom",
    performance: "8,250 / 44%",
  },
  {
    location: "Nike showroom near me",
    performance: "5,432 / 39%",
  },
  {
    location: "Nike slippers",
    performance: "2,000 / 16%",
  },
  {
    location: "Nike products",
    performance: "1,800 / 12%",
  },
];

const Performance = () => {
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Box width="100%" sx={{ textAlign: "left" }}>
      <Grid2 container spacing={3}>
        <Grid2
          size={{ xs: 12, lg: 8 }}
          sx={{
            border: "1px solid #d0d5dd",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5">Performance</Typography>
            <Select defaultValue="google">
              <MenuItem value="google" sx={{ display: "none" }}>
                <Box sx={{ ...flexRowCenterCenter }}>
                  <img src={google} alt="google" />
                  <Typography ml={1} color="primary">
                    Google
                  </Typography>
                </Box>
              </MenuItem>
            </Select>
          </Box>
          <TableContainer
            sx={(theme) => ({
              "& .MuiTableCell-root": {
                color: theme.palette.primary.main,
              },
            })}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#F6F6F6",
                  }}
                >
                  <TableCell color="primary">Locations</TableCell>
                  <TableCell color="primary" sx={{ textAlign: "right" }}>
                    Profile views
                  </TableCell>
                  <TableCell color="primary" sx={{ textAlign: "right" }}>
                    Growth
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {performanceData
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {row.location}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.profileViews}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.growth}%
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              pt: 4,
            }}
          >
            <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
              Showing {rowsPerPage} of {performanceData.length}{" "}
            </Typography>
            <Pagination
              count={performanceData.length / rowsPerPage}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
              sx={(theme) => ({
                "& .MuiPaginationItem-root": {
                  color: theme.palette.primary.main,
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  color: "#1976d2",
                  borderColor: "#1976d2",
                  backgroundColor: theme.palette.secondary.main,
                  fontWeight: 500,
                },
                "& .MuiPaginationItem-root:hover": {
                  backgroundColor: "#e0e0e0",
                },
              })}
            />
          </Box>
        </Grid2>
        <Grid2 size={{ sm: 12, lg: 4 }}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
              mb: 3,
            }}
          >
            <Typography variant="h5" pb={1} sx={{ whiteSpace: "nowrap" }}>
              Top Performing Shops
            </Typography>
            <Box
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                pb: 1,
              }}
            >
              <Box>
                <Typography variant="h1" sx={{ color: "#09A0AB" }}>
                  120
                </Typography>
                <Typography variant="body2" sx={{ color: "#1D1E20" }}>
                  views
                </Typography>
              </Box>
              <CircularProgressWithBuffer progress={75} />
            </Box>
            <TableContainer
              sx={{
                "& .MuiTableCell-root": {
                  color: "#222124",
                  p: "8px 0",
                },
              }}
            >
              <Table>
                <TableBody>
                  {performingShopsData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.location}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.performance}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
              mb: 3,
            }}
          >
            <Typography variant="h5" pb={1} sx={{ whiteSpace: "nowrap" }}>
              Bottom Performing Shops
            </Typography>
            <Box
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                pb: 1,
              }}
            >
              <Box>
                <Typography variant="h1" sx={{ color: "#FA7F65" }}>
                  45
                </Typography>
                <Typography variant="body2" sx={{ color: "#1D1E20" }}>
                  views
                </Typography>
              </Box>
              <CircularProgressWithBuffer progress={20} color="#F2B9AD" />
            </Box>
            <TableContainer
              sx={(theme) => ({
                "& .MuiTableCell-root": {
                  color: theme.palette.primary.main,
                  p: "8px 0",
                },
              })}
            >
              <Table>
                <TableBody>
                  {performingShopsData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.location}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.performance}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Performance;
