import { Box, Grid2, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import womenPost from "assets/images/woman-post.png";
import { Button } from "components/Buttons";
import plus from "assets/icons/add-white.svg";
import google from "assets/icons/google.svg";
import CreatePostModal from "./CreatePostModal";
import { format } from "date-fns";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useParams } from "react-router-dom";

// const posts: any = [
//   {
//     title: "Post Title",
//     description: "Description of the post may vary",
//     date: new Date(),
//     logo: google,
//     image: nikeShoeWhite,
//   },
//   {
//     title: "Post Title",
//     description: "Description of the post may vary",
//     date: new Date(),
//     logo: google,
//     image: nikeShoeRed,
//   },
// ];

const Post: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  const { id } = useParams();
  const [action, setAction] = useState({
    open: isEdit,
    action: isEdit ? "5" : "0",
  });
  const { listing } = useSelector((state: RootState) => state.listingDetails);
  const [imageLoadedStates, setImageLoadedStates] = useState<{
    [key: number]: boolean;
  }>({});

  const handleImageLoad = (index: number) => {
    setImageLoadedStates((prevState) => ({ ...prevState, [index]: true }));
  };

  useEffect(() => {
    if (isEdit) setAction({ open: true, action: "5" });
  }, [isEdit]);
  return (
    <>
      {listing?.posts?.length === 0 ? (
        <Box width="100%" height="100%" sx={{ boxSizing: "border-box" }}>
          <Box
            sx={(theme) => ({
              borderRadius: "8px",
              border: `1px solid ${theme.palette.grey["300"]}`,
              ...flexRowCenterCenter,
              textAlign: "center",
              p: 14,
            })}
          >
            <Box sx={{ maxWidth: 570 }}>
              <img src={womenPost} alt="women-post" />
              <Typography variant="h5" sx={{ pt: 3, pb: 1 }}>
                Create New Post
              </Typography>
              <Typography variant="body2" color="textDisabled" sx={{ pb: 3 }}>
                Upload a post and seamlessly update it across all shops
                simultaneously. Streamline your content management with
                synchronized, multi-shop updates.
              </Typography>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#1650CF", border: "none" }}
                startIcon={<img src={plus} alt="plus" sizes="20px" />}
                onClick={() => setAction({ action: "4", open: true })}
              >
                Create Post
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box width="100%">
          <Typography variant="h1" sx={{ pb: 2 }}>
            {listing?.posts?.length} &nbsp;Posts
          </Typography>
          <Grid2 container>
            {listing?.posts?.map((post: any, index: number) => (
              <Grid2
                size={{ xs: 12, md: 6, lg: 4 }}
                sx={{ pr: 3, pb: 3 }}
                key={index}
              >
                <img
                  src={post?.media_googleUrl}
                  alt="post-image"
                  loading="lazy"
                  style={{
                    borderRadius: "8px",
                    width: "100%",
                    height: imageLoadedStates[index] ? 286 : 0,
                    paddingBottom: "12px",
                    opacity: imageLoadedStates[index] ? 1 : 0,
                  }}
                  onLoad={() => handleImageLoad(index)}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.style.display = "none";
                    const textElement =
                      target.nextElementSibling as HTMLElement;
                    if (textElement) {
                      textElement.style.display = "flex";
                    }
                  }}
                />
                {!imageLoadedStates[index] && (
                  <Box
                    sx={(theme) => ({
                      ...flexRowCenterCenter,
                      height: 286,
                      width: "100%",
                      mb: 2,
                      backgroundColor: theme.palette.grey["200"],
                    })}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        borderRadius: "8px",
                      }}
                    >
                      Image Failed to Load
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                  gap={1}
                >
                  <Tooltip title={post?.summary}>
                    <Typography
                      variant="body2"
                      color="textDisabled"
                      sx={{ pb: 1 }}
                    >
                      {post?.summary?.length > 95
                        ? post?.summary?.slice(0, 95) + "..."
                        : post?.summary}
                    </Typography>
                  </Tooltip>
                  <img src={google} alt="logo" sizes="32px" />
                </Box>
                <Typography variant="caption" color="textDisabled">
                  Date:{" "}
                  {post?.create_time && format(post?.create_time, "dd/MM/yyyy")}
                </Typography>
              </Grid2>
            ))}
          </Grid2>
          <Modal open={isEdit || false} onClose={onClose}>
            <CreatePostModal onClose={onClose} />
          </Modal>
        </Box>
      )}
      <Modal
        open={action.open || (isEdit && action.open ? true : false)}
        onClose={() => {
          setAction({ action: "0", open: false });
          if (isEdit && onClose) onClose();
        }}
      >
        <EditBusinessListing
          setActionOrOpen={({ action = "0", open = true }) => {
            setAction({ action, open });
            if (onClose) onClose();
          }}
          action={action.action}
          selectedBusiness={[{ ...listing?.overView, id }]}
        />
      </Modal>
    </>
  );
};

export default Post;
