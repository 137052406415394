import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FromProps } from "interfaces/BusinessProfiles.types";
import { getBulkActionListing } from "store/bulkActionslistingDetailsSlice";
import { getListing } from "store/listingDetailsSlice";
import apiManager from "utils/apiManager";

interface LabelsState {
  labels: any[] | null;
  count: number | null;
  loading: boolean;
  labelsAction: { success: boolean; message: string } | null;
  error: string | null;
}

const initialState: LabelsState = {
  labels: null,
  count: null,
  loading: false,
  labelsAction: null,
  error: null,
};

export const createLabel = createAsyncThunk(
  "/create-label",
  async (
    {
      label,
      location_id,
      from,
    }: { label: string; location_id: string; from: FromProps },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/create-label", { label });
      if (response)
        thunkAPI.dispatch(
          mapLabel({
            from,
            req: { label_id: response?.data?.data?.id, location_id },
          })
        );
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Failed to create label"
      );
    }
  }
);

export const mapLabel = createAsyncThunk(
  "/label-mapping",
  async (
    {
      from,
      req,
    }: {
      from: FromProps;
      req: {
        label_id: number;
        location_id: string;
      };
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/label-mapping", req);
      thunkAPI.dispatch(
        from === "individual"
          ? getListing(req.location_id)
          : getBulkActionListing(req.location_id)
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to map label"
      );
    }
  }
);

export const removeLabel = createAsyncThunk(
  "/remove-label",
  async (
    {
      from,
      req,
    }: {
      from: FromProps;
      req: {
        label_id: number;
        location_id: string;
      };
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.delete("/remove-label", {
        data: {
          label_id: req.label_id,
          location_id: req.location_id,
        },
      });
      thunkAPI.dispatch(
        from === "individual"
          ? getListing(req.location_id)
          : getBulkActionListing(req.location_id)
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to map label"
      );
    }
  }
);

export const getLabels = createAsyncThunk(
  "fetchLabel",
  async ({ page, limit }: { page?: number; limit?: number } = {}, thunkAPI) => {
    try {
      const response = await apiManager.get(`fetchLabel`, {
        params: { page, limit },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to get labels"
      );
    }
  }
);

const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    resetLabelsAction: (state) => {
      state.labelsAction = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //create labels
      .addCase(createLabel.pending, (state) => {
        state.loading = true;
        state.labelsAction = null;
      })
      .addCase(createLabel.fulfilled, (state, action) => {
        state.loading = false;
        state.labelsAction = { success: true, message: action.payload };
      })
      .addCase(createLabel.rejected, (state, action: any) => {
        state.loading = false;
        state.labelsAction = {
          success: false,
          message: action.payload as string,
        };
      })
      //map labels
      .addCase(mapLabel.pending, (state) => {
        state.loading = true;
        state.labelsAction = null;
        state.error = null;
      })
      .addCase(mapLabel.fulfilled, (state, action) => {
        state.loading = false;
        state.labelsAction = { success: true, message: action.payload.data };
        state.error = null;
      })
      .addCase(mapLabel.rejected, (state, action) => {
        state.loading = false;
        state.labelsAction = {
          success: false,
          message: action.payload as string,
        };
      })
      //get labels
      .addCase(getLabels.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.labelsAction = null;
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        state.labels = action.payload.data;
        state.count = action.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(getLabels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      //remove labels
      .addCase(removeLabel.pending, (state) => {
        state.loading = true;
        state.labelsAction = null;
        state.error = null;
      })
      .addCase(removeLabel.fulfilled, (state, action) => {
        state.loading = false;
        state.labelsAction = {
          success: true,
          message: action.payload.data as string,
        };
        state.error = null;
      })
      .addCase(removeLabel.rejected, (state, action) => {
        state.loading = false;
        state.labelsAction = {
          success: false,
          message: action.payload as string,
        };
      });
  },
});

export const { resetLabelsAction } = labelsSlice.actions;

export default labelsSlice.reducer;
