import {
  Autocomplete,
  Box,
  Chip,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { flexRowCenterCenter } from "utils/styles";
import { CustomTextField } from "components/TextField";
import search from "assets/icons/search.svg";
import close from "assets/icons/close-white.svg";
import plus from "assets/icons/add-white.svg";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";
import {
  createLabel,
  getLabels,
  mapLabel,
  removeLabel,
  resetLabelsAction,
} from "store/labelsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { useParams } from "react-router-dom";
import { groupMapping, removeGroup, resetGroupsAction } from "store/groupSlice";
import { sweetAlert } from "utils/sweetAlert";
import { FromProps } from "interfaces/BusinessProfiles.types";

export const FiltersComponent: React.FC<{
  isEdit?: boolean;
  onClose?: () => void;
  selectedBusiness: any;
  from: FromProps;
}> = ({ selectedBusiness, from }) => {
  const { listing, loading: listingLoading } = useSelector(
    (state: RootState) => state.listingDetails
  );
  const { bulkActionListing, loading: bulkActionListingLoading } = useSelector(
    (state: RootState) => state.bulkActionsListingDetails
  );
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<any>(
    from === "individual" ? listing?.label || [] : bulkActionListing?.label
  );
  const {
    labels: initialLabels,
    error: labelsError,
    labelsAction,
  } = useSelector((state: RootState) => state.labels);
  const [labels, setLabels] = useState(initialLabels);
  const {
    groups: initialGroups,
    error: groupsError,
    groupsAction,
  } = useSelector((state: RootState) => state.groups);
  const [groups, setGroups] = useState(initialGroups);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setLabels(initialLabels || []);
  }, [initialLabels]);

  useEffect(() => {
    setGroups(initialGroups || []);
  }, [initialGroups]);

  useEffect(() => {
    if (selectedBusiness.length === 1) {
      setSelectedLabels(
        from === "individual"
          ? listing?.label || []
          : bulkActionListing?.label || []
      );
      setSelectedGroup(
        from === "individual" ? listing?.group[0] : bulkActionListing?.group[0]
      );
    } else {
      setSelectedLabels([]);
      setSelectedGroup(null);
    }
  }, [selectedBusiness, listing, bulkActionListing]);

  useEffect(() => {
    const fetchData = async () => {
      if (labelsAction && !labelsAction?.success) {
        await sweetAlert({
          title: "Error!",
          icon: "error",
          text: labelsAction?.message || "Something went wrong!",
        });
      } else if (labelsAction && labelsAction?.success) {
        await sweetAlert({
          title: "Success!",
          icon: "success",
          text: labelsAction?.message,
        });
      }
      dispatch(resetLabelsAction());
      dispatch(resetGroupsAction());
    };

    fetchData();
  }, [labelsAction, dispatch]);

  useEffect(() => {
    if (labelsError)
      sweetAlert({
        title: "Error!",
        icon: "error",
        text: labelsError || `Something went wrong!`,
      });
  }, [labelsError]);

  useEffect(() => {
    if (groupsAction && !groupsAction.success)
      sweetAlert({
        title: "Error!",
        icon: "error",
        text: groupsAction.message,
      });
    else if (groupsAction && groupsAction.success)
      sweetAlert({
        title: "Success!",
        icon: "success",
        text: groupsAction.message,
      });
  }, [groupsAction]);

  useEffect(() => {
    if (groupsError)
      sweetAlert({
        title: "Error!",
        icon: "error",
        text: groupsError || `Something went wrong!`,
      });
  }, [groupsError]);

  useEffect(() => {
    dispatch(getLabels({}));
    return () => {
      dispatch(resetGroupsAction());
      dispatch(resetLabelsAction());
    };
  }, []);

  const handleChange = (event: any, newValue: any) => {
    if (selectedBusiness.length === 1) {
      if (selectedBusiness[0]?.id) {
        if (typeof newValue === "string" && !selectedLabels.includes(newValue))
          dispatch(
            createLabel({
              label: newValue,
              location_id: selectedBusiness[0]?.id,
              from,
            })
          );
        else if (
          newValue?.inputValue &&
          !selectedLabels.includes(newValue.inputValue)
        )
          dispatch(
            createLabel({
              label: newValue?.inputValue,
              location_id: selectedBusiness[0]?.id,
              from,
            })
          );
        else if (
          newValue &&
          !selectedLabels.some((label: any) => label.id === newValue.id)
        ) {
          if (newValue)
            dispatch(
              mapLabel({
                from,
                req: {
                  label_id: newValue.id,
                  location_id: selectedBusiness[0]?.id,
                },
              })
            );
        }
      }
    }
  };

  const handleGroupChange = (event: any, newValue: any) => {
    if (newValue && selectedBusiness.length === 1 && selectedBusiness[0]?.id) {
      if (selectedGroup?.id !== newValue?.id)
        dispatch(
          groupMapping({
            from,
            req: {
              location_id: selectedBusiness[0]?.id,
              group_id: newValue?.id,
            },
          })
        );
    }
  };

  const onRemoveLabel = async (label: any) => {
    if (selectedBusiness.length === 1 && selectedBusiness[0]?.id) {
      await dispatch(
        removeLabel({
          from,
          req: {
            label_id: label?.id,
            location_id: selectedBusiness[0]?.id,
          },
        })
      );
    }
  };

  return (
    <Box width="100%">
      <Box
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          py: 2,
          pr: 2,
        })}
      >
        <Typography variant="h5" sx={() => ({ fontWeight: 600, pb: 1 })}>
          Profile Group
        </Typography>
        <Typography variant="body2" color="textDisabled">
          Only one profile group can be used for adding into selected business
          profile. Chosen group name will overwrite the already existing group
          name in selected profiles
        </Typography>
        <Box py={2}>
          {listingLoading || bulkActionListingLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: 6, width: 90, height: 32 }}
            />
          ) : selectedGroup ? (
            <Chip
              variant="filled"
              sx={(theme) => ({
                backgroundColor: "#1650CF",
                color: theme.palette.secondary.main,
              })}
              label={selectedGroup?.group_name}
              deleteIcon={
                <img
                  src={close}
                  alt="close"
                  style={{ width: 14, height: 14 }}
                />
              }
              onDelete={() => {
                if (selectedBusiness[0]?.id)
                  dispatch(
                    removeGroup({
                      from,
                      req: {
                        group_id: selectedGroup?.id,
                        location_id: selectedBusiness[0]?.id,
                      },
                    })
                  );
              }}
            />
          ) : null}
        </Box>
        <Autocomplete
          value=""
          onChange={handleGroupChange}
          filterOptions={(options, params) => {
            const { inputValue } = params;
            const filtered =
              options.filter((option) =>
                option.group_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) || [];
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="select-groups"
          options={groups || []}
          getOptionLabel={(option) => {
            if (typeof option === "string") return option;
            if (option.inputValue) return option.inputValue;
            return option?.group_name;
          }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option?.id}
                style={{ fontWeight: 400, fontSize: "14px" }}
              >
                {option.group_name}
              </li>
            );
          }}
          sx={{
            width: 300,
            fontWeight: 400,
            fontSize: "14px",
            minWidth: "60%",
          }}
          freeSolo
          size="small"
          renderInput={(props) => (
            <CustomTextField
              sx={{ p: 0 }}
              {...props}
              variant="outlined"
              placeholder="Search Labels"
              slotProps={{
                input: {
                  ...props.InputProps,
                  endAdornment: (
                    <>
                      {props.InputProps.endAdornment}
                      <img
                        src={search}
                        alt="search"
                        style={{ width: 20, height: 20 }}
                      />
                    </>
                  ),
                },
              }}
            />
          )}
        />
        <Box
          gap={1}
          sx={() => ({
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            flexWrap: "wrap",
            maxWidth: "60%",
            py: 2,
          })}
        >
          {groups?.slice(0, 5)?.map((item) => (
            <Chip
              key={item.id}
              label={item.group_name}
              icon={<img src={plus} alt="plus" />}
              sx={(theme) => ({
                backgroundColor: theme.palette.grey["500"],
                color: theme.palette.secondary.main,
                fontWeight: 600,
              })}
              onClick={async () => {
                if (selectedBusiness[0]?.id)
                  await dispatch(
                    groupMapping({
                      from,
                      req: {
                        location_id: selectedBusiness[0]?.id,
                        group_id: item.id,
                      },
                    })
                  );
              }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={() => ({ py: 2 })}>
        <Typography variant="h5" sx={() => ({ fontWeight: 600, pb: 1 })}>
          Filter Labels
        </Typography>
        <Typography variant="body2" color="textDisabled">
          You can create or choose multiple labels to create various levels of
          filter options
        </Typography>
        <Box py={2} gap={1} sx={{ display: "flex", flexWrap: "wrap" }}>
          {listingLoading || bulkActionListingLoading
            ? [1, 2].map((item) => (
                <Skeleton
                  key={item}
                  variant="rectangular"
                  sx={{ borderRadius: 6, width: 90, height: 32 }}
                />
              ))
            : selectedLabels?.map((label: any, index: number) => (
                <Chip
                  key={index}
                  variant="filled"
                  sx={(theme) => ({
                    backgroundColor: "#1650CF",
                    color: theme.palette.secondary.main,
                  })}
                  label={label.label_name}
                  deleteIcon={
                    <img
                      src={close}
                      alt="close"
                      style={{ width: 14, height: 14 }}
                    />
                  }
                  onDelete={() => onRemoveLabel(label)}
                />
              ))}
        </Box>
        <Autocomplete
          value=""
          onChange={handleChange}
          filterOptions={(options, params) => {
            const { inputValue } = params;
            const filtered =
              options.filter((option) =>
                option.label_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) || [];
            const isExisting = options.some((option) =>
              option.label_name.toLowerCase().includes(inputValue.toLowerCase())
            );

            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                label_name: `"${inputValue}" (New Label)`,
              });
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="select-labels"
          options={labels || []}
          getOptionLabel={(option) => {
            if (typeof option === "string") return option;
            if (option.inputValue) return option.inputValue;
            return option?.label_name;
          }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option?.id}
                style={{ fontWeight: 400, fontSize: "14px" }}
              >
                {option.label_name}
              </li>
            );
          }}
          sx={{
            width: 300,
            fontWeight: 400,
            fontSize: "14px",
            minWidth: "60%",
          }}
          freeSolo
          size="small"
          renderInput={(props) => (
            <CustomTextField
              sx={{ p: 0 }}
              {...props}
              variant="outlined"
              placeholder="Search Labels"
              slotProps={{
                input: {
                  ...props.InputProps,
                  endAdornment: (
                    <>
                      {props.InputProps.endAdornment}
                      <img
                        src={search}
                        alt="search"
                        style={{ width: 20, height: 20 }}
                      />
                    </>
                  ),
                },
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

const Filters: React.FC<{
  isEdit?: boolean;
  onClose?: () => void;
}> = ({ isEdit, onClose }) => {
  const { id } = useParams();
  const { listing } = useSelector((state: RootState) => state.listingDetails);
  const [action, setAction] = useState({
    open: isEdit,
    action: isEdit ? "6" : "0",
  });

  useEffect(() => {
    if (isEdit) setAction({ open: true, action: "6" });
  }, [isEdit]);

  return (
    <Box width="100%">
      <FiltersComponent
        selectedBusiness={[{ ...listing?.overView, id }]}
        from="individual"
      />
      <Modal
        open={action.open || (isEdit && action.open ? true : false)}
        onClose={() => {
          setAction({ action: "0", open: false });
          if (isEdit && onClose) onClose();
        }}
      >
        <EditBusinessListing
          setActionOrOpen={({ action = "0", open = true }) => {
            setAction({ action, open });
            if (onClose) onClose();
          }}
          action={action.action}
          selectedBusiness={[{ ...listing?.overView, id }]}
        />
      </Modal>
    </Box>
  );
};

export default Filters;
