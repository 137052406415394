import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

const initialRatings = [
  { id: 1, number: "5" },
  { id: 2, number: "4" },
  { id: 3, number: "3" },
  { id: 4, number: "2" },
  { id: 5, number: "1" },
];

interface ListingsState {
  listings: any[] | null;
  count: number | null;
  loading: boolean;
  statesList: { state: string; count: number }[] | null;
  citiesList: { city: string; count: number }[] | null;
  error: string | null;
  filters: {
    pagination: { page: number; rowsPerPage: number };
    searchText: string;
    states: {
      filteredList: { state: string; count: number }[] | null;
      search: string;
      selectedList: string[] | null;
    };
    cities: {
      filteredList: { city: string; count: number }[] | null;
      search: string;
      selectedList: string[] | null;
    };
    ratings: {
      filteredList: { id: number; number: string }[];
      selectedList: string[] | null;
    };
  };
}

const initialState: ListingsState = {
  listings: null,
  count: null,
  loading: false,
  statesList: null,
  citiesList: null,
  error: null,
  filters: {
    pagination: { page: 1, rowsPerPage: 10 },
    searchText: "",
    states: {
      filteredList: null,
      search: "",
      selectedList: [],
    },
    cities: {
      filteredList: null,
      search: "",
      selectedList: [],
    },
    ratings: {
      filteredList: initialRatings,
      selectedList: [],
    },
  },
};

export const getListings = createAsyncThunk(
  "/location-list",
  async (
    {
      req,
      signal,
    }: {
      req: {
        page: number;
        limit: number;
        state?: string;
        city?: string;
        rating?: string;
        label?: string;
        sort?: any;
        search?: string;
        group_id?: string;
      };
      signal?: AbortSignal;
    },
    thunkAPI
  ) => {
    try {
      const abortController = new AbortController(); // Create a new AbortController
      const { signal: newSignal } = abortController;
      const response = await apiManager.post("/location-list", req, {
        signal: signal || newSignal,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to get listings"
      );
    }
  }
);

const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setStates: (state, action) => {
      state.filters.states = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //get listings list
      .addCase(getListings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getListings.fulfilled, (state, action: any) => {
        state.listings = action.payload.data;
        state.count = action.payload.totalCount;
        state.statesList = action.payload.stateFilterList;
        state.citiesList = action.payload.cityFilterList;
        state.loading = false;
      })
      .addCase(getListings.rejected, (state, action) => {
        state.listings = null;
        state.statesList = null;
        state.citiesList = null;
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setPagination, setStates } = listingsSlice.actions;

export default listingsSlice.reducer;
