import styled from "@emotion/styled";
import {
  Box,
  FormControlLabel,
  Typography,
  TextField as TextInput,
  RadioGroup,
  Divider,
  IconButton,
  Autocomplete,
} from "@mui/material";
import Checkbox from "components/Checkbox";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  bulkActionsContentStyle,
  bulkActionsInnerContentStyle,
  flexRowCenterCenter,
} from "utils/styles";
import plusBlue from "assets/icons/plus-blue.svg";
import close from "assets/icons/close.svg";
import checkedRound from "assets/icons/checked-round.svg";
import Radio from "components/Radio";
import {
  BusinessProfile,
  ProfileTab,
  Section,
} from "interfaces/BusinessProfiles.types";
import { Map, WorkingHours } from "../Components";
import { Button, ButtonOutline } from "components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import {
  bulkLocationUpdate,
  getCategoriesList,
  locationAttributesUpdate,
  locationUpdate,
  resetStatus,
} from "store/bulkActionsSlice";
import {
  convertAttributes,
  hoursArrayToObject,
  transformHours,
} from "utils/functions";

export const StyledTextField = styled(TextInput)(() => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "#f8f1f182",
  },
  "& .MuiFilledInput-root::before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-root::after": {
    borderBottom: "none",
  },
  "& .MuiInputBase-input": {
    fontWeight: 400,
    fontSize: "14px",
    p: "0px !important",
  },
  "& .MuiFilledInput-input": {
    padding: "0px !important",
  },
  "& .MuiInputBase-root": {
    padding: "4px 8px!important",
  },
}));

type Step = {
  id: number;
  label: string;
  subLabel: string;
};

type AttributesData = {
  id: number;
  title: string;
  description: string;
  options: { id: string; value: string; selectedValue: string | null }[];
};

const steps: Step[] = [
  { id: 1, label: "Accessibility", subLabel: "Select product" },
  { id: 2, label: "Amenities", subLabel: "Select type" },
  { id: 3, label: "Crowd", subLabel: "Select topic" },
  { id: 4, label: "From the business", subLabel: "Select date & time" },
  { id: 5, label: "Planning", subLabel: "Select HCP" },
  { id: 6, label: "Parking", subLabel: "Enter location for engagement" },
  { id: 7, label: "Service options", subLabel: "Invite speaker" },
];

const initialAttributesData: AttributesData[] = [
  {
    id: 1,
    title: "Accessibility",
    description:
      "Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.",
    options: [
      {
        id: "has_hearing_loop",
        value: "Has assistive hearing loop",
        selectedValue: null,
      },
      {
        id: "has_wheelchair_accessible_seating",
        value: "Has wheelchair-accessible seating",
        selectedValue: null,
      },
      {
        id: "has_wheelchair_accessible_restroom",
        value: "Has wheelchair-accessible toilet",
        selectedValue: null,
      },
    ],
  },
  {
    id: 2,
    title: "Amenities",
    description:
      "Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.",
    options: [
      {
        id: "has_restroom_unisex",
        value: "Has gender-neutral toiletsm",
        selectedValue: null,
      },
    ],
  },
  {
    id: 3,
    title: "Crowd",
    description:
      "Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.",
    options: [
      { id: "welcomes_lgbtq", value: "LGBTQ+ friendly", selectedValue: null },
    ],
  },
  {
    id: 4,
    title: "From the business",
    description: `Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services. If you're not the owner, confirm with the owner before adding an attribute.`,
    options: [
      {
        id: "is_owned_by_women",
        value: "Identifies as women-owned",
        selectedValue: null,
      },
    ],
  },
  {
    id: 5,
    title: "Planning",
    description:
      "Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.",
    options: [
      {
        id: "requires_appointments",
        value: "Appointment required",
        selectedValue: null,
      },
    ],
  },
  {
    id: 6,
    title: "Parking",
    description:
      "Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.",
    options: [
      {
        id: "has_parking_garage_free",
        value: "Free multi-storey car park",
        selectedValue: null,
      },
      {
        id: "has_parking_street_free",
        value: "Free of charge street parking",
        selectedValue: null,
      },
      {
        id: "has_parking_lot_free",
        value: "Free parking lot",
        selectedValue: null,
      },
      {
        id: "has_onsite_parking",
        value: "On-site parking",
        selectedValue: null,
      },
      {
        id: "has_parking_garage_paid",
        value: "Paid multi-storey car park",
        selectedValue: null,
      },
      {
        id: "has_parking_lot_paid",
        value: "Paid parking lot",
        selectedValue: null,
      },
      {
        id: "has_parking_street_paid",
        value: "Paid street parking",
        selectedValue: null,
      },
    ],
  },
  {
    id: 7,
    title: "Service options",
    description:
      "Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.",
    options: [
      {
        id: "offers_online_appointments",
        value: "Offers online appointments",
        selectedValue: null,
      },
      {
        id: "",
        value: "On-site services available",
        selectedValue: null,
      },
    ],
  },
];

export const initialWorkingHoursData = {
  SUNDAY: [
    {
      openDay: "SUNDAY",
      closeDay: "SUNDAY",
      openTime: { hours: 0 },
      closeTime: { hours: 0 },
    },
  ],
  MONDAY: [
    {
      openDay: "MONDAY",
      closeDay: "MONDAY",
      openTime: { hours: 0 },
      closeTime: { hours: 0 },
    },
  ],
  TUESDAY: [
    {
      openDay: "TUESDAY",
      closeDay: "TUESDAY",
      openTime: { hours: 0 },
      closeTime: { hours: 0 },
    },
  ],
  WEDNESDAY: [
    {
      openDay: "WEDNESDAY",
      closeDay: "WEDNESDAY",
      openTime: { hours: 0 },
      closeTime: { hours: 0 },
    },
  ],
  THURSDAY: [
    {
      openDay: "THURSDAY",
      closeDay: "THURSDAY",
      openTime: { hours: 0 },
      closeTime: { hours: 0 },
    },
  ],
  FRIDAY: [
    {
      openDay: "FRIDAY",
      closeDay: "FRIDAY",
      openTime: { hours: 0 },
      closeTime: { hours: 0 },
    },
  ],
  SATURDAY: [
    {
      openDay: "SATURDAY",
      closeDay: "SATURDAY",
      openTime: { hours: 0 },
      closeTime: { hours: 0 },
    },
  ],
};

const initialFormData = {
  title: "",
  categoriesDisplayName: "",
  addcategories: [],
  description: "",
  hours: [],
  phoneNumbers: "",
  additionalPhone: [],
  openInfo: "OPEN1",
  openDate: "",
  moreHour: null,
  specialHours: null,
  store_address: null,
  mapCenter: { lng: 0, lat: 0 },
  state: "",
  postalCode: "",
  city: "",
  country: "India",
  is_add_info_title: false,
  is_add_info_description: false,
};

// type FormDataProps = {
//   title: string;
//   categoriesDisplayName: string;
//   description: string;
//   phoneNumbers: string;
//   webSite: string;
//   hours: {
//     openDay: string;
//     closeDay: string;
//     openTime: {
//       hours?: number;
//       minutes?: number;
//     };
//     closeTime: {
//       hours?: number;
//       minutes?: number;
//     };
//   }[];
// };

const Profile: React.FC<{
  selectedBusinessProfiles: BusinessProfile[];
  selectedRef?: Section;
  setActionOrOpen: any;
}> = forwardRef(
  ({ selectedBusinessProfiles, selectedRef, setActionOrOpen }, ref) => {
    const tabsRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<string>("100vh");
    const dispatch = useDispatch<AppDispatch>();
    const { bulkActionListing: listing, loading } = useSelector(
      (state: RootState) => state.bulkActionsListingDetails
    );
    const { categoriesList } = useSelector(
      (state: RootState) => state.bulkActions.data
    );
    const [formData, setFormData] = useState<any>(initialFormData);

    useEffect(() => {
      dispatch(getCategoriesList());
    }, []);

    useEffect(() => {
      if (selectedBusinessProfiles?.length === 1) {
        const newFormData = listing?.overView;
        //openInfo
        const openInfo =
          listing?.overView?.openInfo === "OPEN"
            ? listing?.overView?.hours === ""
              ? "OPEN2"
              : "OPEN1"
            : listing?.overView?.openInfo;
        //regularHours
        let hours = null;
        let moreHour = [];
        let specialHours = [];
        let store_address = null;
        if (listing?.overView?.hours) {
          const updatedWorkingHours = hoursArrayToObject(
            listing?.overView?.hours || []
          );
          hours = updatedWorkingHours;
        } else hours = initialWorkingHoursData;
        //moreHours
        if (listing?.overView?.moreHour) {
          const updatedMoreHours = listing?.overView?.moreHour?.periods
            ? [
                {
                  hoursTypeId: listing?.overView?.moreHour?.hoursTypeId,
                  periods: hoursArrayToObject(
                    listing?.overView?.moreHour?.periods || []
                  ),
                },
              ]
            : listing?.overView?.moreHour
              ? listing?.overView?.moreHour?.map((moreHr: any) => {
                  return {
                    hoursTypeId: moreHr?.hoursTypeId,
                    periods: hoursArrayToObject(moreHr?.periods || []),
                  };
                })
              : [];
          moreHour = updatedMoreHours;
        }
        //specialHours
        if (listing?.overView?.specialHours) {
          const result: any = {};
          listing?.overView?.specialHours?.forEach((entry: any) => {
            const dateKey = `${entry.startDate.year}-${entry.startDate.month.toString().padStart(2, "0")}-${entry.startDate.day.toString().padStart(2, "0")}`;
            if (!result[dateKey]) {
              result[dateKey] = [];
            }
            result[dateKey].push(entry);
          });
          specialHours = result;
        }
        //location
        if (listing?.overView?.store_address) {
          store_address = {
            ...listing?.overView?.store_address,
            addressLines:
              listing?.overView?.store_address?.addressLines.length === 1
                ? [...listing.overView.store_address.addressLines, ""]
                : listing?.overView?.store_address?.addressLines > 1
                  ? listing?.overView?.store_address?.addressLines
                  : ["", ""],
          };
        }
        // latitude, longitude
        let lng = 0;
        let lat = 0;
        if (
          listing?.overView?.latitude !== "undefined" &&
          listing?.overView?.latitude &&
          listing?.overView?.longitude !== "undefined" &&
          listing?.overView?.longitude
        ) {
          lat = isNaN(listing?.overView?.latitude)
            ? 0
            : parseFloat(listing?.overView?.latitude);
          lng = isNaN(listing?.overView?.longitude)
            ? 0
            : parseFloat(listing?.overView?.longitude);
        }
        setFormData({
          ...newFormData,
          hours,
          moreHour,
          specialHours,
          openInfo,
          store_address,
          mapCenter: { lat, lng },
          addcategories: listing?.overView?.addcategories || [],
          is_add_info_title: false,
          is_add_info_description: false,
        });
        const apiMap: { [key: string]: boolean } = {};
        if (listing?.overView?.attributes)
          listing?.overView?.attributes?.forEach((attribute: any) => {
            const attributeName = attribute.name.split("/")[1];
            apiMap[attributeName] = attribute.values[0];
          });

        initialAttributesData.forEach((category) => {
          category.options.forEach((option) => {
            const attributeName = option.id;
            if (Object.prototype.hasOwnProperty.call(apiMap, attributeName)) {
              option.selectedValue =
                apiMap[attributeName] === true
                  ? "Yes"
                  : apiMap[attributeName] === false
                    ? "No"
                    : "";
            }
          });
        });

        setAttributesData(initialAttributesData);
      } else setFormData({ ...initialFormData, hours: hoursArrayToObject([]) });
    }, [listing]);

    useEffect(() => {
      if (tabsRef?.current) {
        const tabsHeight = tabsRef?.current?.scrollHeight || 0;
        const calculatedHeight = `calc(100vh - 217px - ${tabsHeight}px)`;
        setHeight(calculatedHeight);
      }
    }, [tabsRef?.current?.scrollHeight]);

    useEffect(() => {
      if (selectedRef) {
        const sectionRef = sectionRefs[selectedRef]?.current;
        if (sectionRef) {
          sectionRef.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }, [selectedRef]);

    const [activeAttribute, setActiveAttribute] = useState(1);
    const [attributesData, setAttributesData] = useState<AttributesData[]>(
      initialAttributesData
    );
    const selectedAtrributeData = attributesData.filter(
      (data) => data.id === activeAttribute
    )[0];
    const [profileTabs, setProfileTabs] = useState<ProfileTab[]>([
      { id: 1, name: "about", isActive: true },
      { id: 2, name: "contact", isActive: false },
      { id: 3, name: "location", isActive: false },
      { id: 4, name: "hours", isActive: false },
      { id: 5, name: "attributes", isActive: false },
    ]);
    const sectionRefs = {
      about: useRef<HTMLDivElement>(null),
      contact: useRef<HTMLDivElement>(null),
      location: useRef<HTMLDivElement>(null),
      hours: useRef<HTMLDivElement>(null),
      attributes: useRef<HTMLDivElement>(null),
    };
    // const [selectedPlace, setSelectedPlace] =
    //   useState<google.maps.places.PlaceResult | null>(null);
    // console.log(selectedPlace, setSelectedPlace);
    const scrollToSection = (currentProfileTab: ProfileTab) => {
      const sectionRef =
        sectionRefs[currentProfileTab?.name as Section]?.current;
      if (sectionRef) {
        sectionRef?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      setProfileTabs(
        profileTabs.map((profileTab) => ({
          ...profileTab,
          isActive: profileTab.id === currentProfileTab.id,
        }))
      );
    };

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setProfileTabs(
                profileTabs.map((profileTab) => ({
                  ...profileTab,
                  isActive: profileTab.name === entry.target.id,
                }))
              );
            }
          });
        },
        { threshold: 0.5 }
      );

      Object.values(sectionRefs).forEach((ref) => {
        if (ref?.current) {
          observer.observe(ref?.current);
        }
      });

      return () => observer.disconnect();
    }, []);

    const handleFormData = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: name === "description" ? value.slice(0, 750) : value,
      });
    };

    const updateFormData = ({ key, value }: { key: any; value: any }) => {
      setFormData({ ...formData, [key]: value });
    };

    const saveChanges = async () => {
      const {
        description,
        title,
        openDate,
        website,
        hours,
        specialHours,
        store_address,
        // moreHour,
        openInfo,
        phoneNumbers,
        // additionalPhone,
        categoriesDisplayName,
        addcategories,
        mapCenter,
        is_add_info_title,
        is_add_info_description,
      } = formData;
      const openingDate = new Date(openDate);
      if (selectedBusinessProfiles.length === 1) {
        await dispatch(
          locationUpdate({
            location_id: selectedBusinessProfiles[0]?.id,
            description,
            title,
            openingDate: {
              year: openingDate?.getFullYear(),
              month: openingDate?.getMonth() + 1,
              day: openingDate?.getDate(),
            },
            openInfo: ["OPEN1", "OPEN2"].includes(openInfo) ? "OPEN" : openInfo,
            web_site: website,
            regularHours: transformHours(hours),
            phoneNumbers,
            // moreHour: moreHour
            //   ?.map((hour: any) => ({
            //     hoursTypeId: hour?.hoursTypeId,
            //     periods: transformHours(hour.periods),
            //   }))
            //   .flat(),
            // phoneNumbers,
            // additionalPhone,
            specialHours: transformHours(specialHours),
            storefrontAddress: store_address,
            primaryCategory:
              typeof categoriesDisplayName === "string"
                ? categoriesList.find(
                    (category: any) => category?.name === categoriesDisplayName
                  )?.id
                : categoriesDisplayName?.id,
            addcategories:
              addcategories
                ?.map((category: any) => {
                  console.log({ category });
                  if (category?.id) return category?.id;
                  else if (category?.displayName)
                    return categoriesList.find(
                      (listCategory: any) =>
                        category?.name?.split("/")[1] ===
                        listCategory?.category_identity
                    )?.id;
                  else if (category?.name)
                    return categoriesList.find(
                      (listCategory: any) =>
                        category?.name === listCategory?.category_identity
                    )?.id;
                  return "";
                })
                .filter((category: any) => category) || "",
            latlng: { latitude: mapCenter?.lat, longitude: mapCenter?.lng },
          })
        );
      } else {
        const payload: any = {
          location_ids: selectedBusinessProfiles?.map(
            (profile: any) => profile.id
          ),
          is_add_info_title,
          is_add_info_description,
        };
        if (title) payload.title = title;
        if (openDate)
          payload.openingDate = {
            year: openingDate?.getFullYear(),
            month: openingDate?.getMonth() + 1,
            day: openingDate?.getDate(),
          };
        if (description) payload.description = description;
        if (website) payload.web_site = website;
        if (transformHours(hours).length > 0)
          payload.regular_hours = transformHours(hours);
        if (specialHours) transformHours(specialHours);
        if (phoneNumbers) payload.phoneNumbers = phoneNumbers;
        // if (moreHour) payload.moreHour = moreHour?.map((hour: any) => ({
        //     hoursTypeId: hour?.hoursTypeId,
        //     periods: transformHours(hour.periods),
        //   }))
        //   .flat()

        await dispatch(bulkLocationUpdate(payload));
      }
      await dispatch(
        locationAttributesUpdate({
          location_id: selectedBusinessProfiles?.map(
            (profile: any) => profile?.id
          ),
          attributes: convertAttributes(attributesData || []) || "",
        })
      );
      setActionOrOpen({ open: false });
      dispatch(resetStatus());
    };
    console.log(
      { formData },
      typeof formData?.categoriesDisplayName === "string"
    );
    return (
      <Box ref={ref} sx={bulkActionsContentStyle}>
        <Box sx={bulkActionsInnerContentStyle}>
          <Box
            ref={tabsRef}
            sx={(theme) => ({
              position: "sticky",
              top: -1,
              backgroundColor: theme.palette.secondary.main,
              zIndex: 10,
              p: 2,
              pb: 0,
            })}
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Business Information
            </Typography>
            <Box
              sx={(theme) => ({
                ...flexRowCenterCenter,
                justifyContent: "flex-start",
                flexWrap: "wrap",
                pt: 1,
                borderBottom: `1px solid ${theme.palette.grey["200"]}`,
              })}
            >
              {profileTabs.map((tab) => (
                <Typography
                  component="a"
                  key={tab.id}
                  variant="body2"
                  sx={(theme) => ({
                    textDecoration: "none",
                    textTransform: "capitalize",
                    borderBottom: tab.isActive ? "4px solid #440BFF" : "",
                    mr: 2,
                    p: 1,
                    cursor: "pointer",
                    boxSizing: "border-box",
                    fontWeight: 400,
                    color: tab.isActive
                      ? theme.palette.primary.main
                      : theme.palette.text.disabled,
                  })}
                  onClick={() => scrollToSection(tab)}
                >
                  {tab.name}
                </Typography>
              ))}
            </Box>
          </Box>
          {loading ? (
            <Box sx={{ p: 2 }}>Loading...</Box>
          ) : (
            <Box
              px={2}
              sx={{
                maxHeight: height,
                overflowY: "auto",
                boxSizing: "border-box",
              }}
            >
              <Box
                id="about"
                ref={sectionRefs?.about}
                sx={(theme) => ({
                  py: 2,
                  borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                })}
              >
                <Typography variant="body1" sx={{ pb: 1 }}>
                  About your business
                </Typography>
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2">Business Name</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={formData?.is_add_info_title}
                        sx={(theme) => ({ color: theme.palette.grey["300"] })}
                        onChange={(e) =>
                          updateFormData({
                            key: "is_add_info_title",
                            value: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Add additional information"
                    sx={() => ({
                      mr: 0,
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                        fontWeight: 200,
                      },
                    })}
                  />
                </Box>
                <StyledTextField
                  variant="filled"
                  value={formData?.title}
                  fullWidth
                  multiline
                  name="title"
                  onChange={handleFormData}
                />
                <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
                  Primary Business Category
                </Typography>
                <Box
                  gap={1}
                  sx={{
                    py: 1,
                    ...flexRowCenterCenter,
                    justifyContent: "flex-start",
                  }}
                >
                  <Autocomplete
                    value={
                      typeof formData?.categoriesDisplayName === "string"
                        ? formData?.categoriesDisplayName
                        : formData?.categoriesDisplayName?.name
                    }
                    onChange={(e, newValue) =>
                      updateFormData({
                        key: "categoriesDisplayName",
                        value: newValue,
                      })
                    }
                    filterOptions={(options, params) => {
                      const { inputValue } = params;
                      const filtered =
                        options.filter((option) =>
                          option?.name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        ) || [];
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="primary-category-autocomplete"
                    options={categoriesList || []}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") return option;
                      if (option.inputValue) return option.inputValue;
                      return option?.name;
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          key={option?.id}
                          style={{ fontWeight: 400, fontSize: "14px" }}
                        >
                          {option?.name}
                        </li>
                      );
                    }}
                    sx={{
                      width: 300,
                      fontWeight: 400,
                      fontSize: "14px",
                      minWidth: "60%",
                    }}
                    freeSolo
                    size="small"
                    renderInput={(props) => (
                      <StyledTextField {...props} variant="filled" fullWidth />
                    )}
                  />
                </Box>
                <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
                  Additional Categories
                </Typography>
                {formData?.addcategories?.map(
                  (category: any, index: number) => (
                    <Box
                      gap={1}
                      sx={{
                        py: 1,
                        ...flexRowCenterCenter,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Autocomplete
                        value={category?.displayName || category?.name}
                        onChange={(e, newValue) =>
                          updateFormData({
                            key: "addcategories",
                            value: formData?.addcategories?.map(
                              (currentCategory: any, currentIndex: number) => {
                                if (currentIndex === index) {
                                  return newValue;
                                }
                                return currentCategory;
                              }
                            ),
                          })
                        }
                        filterOptions={(options, params) => {
                          const { inputValue } = params;
                          const filtered =
                            options.filter((option) =>
                              option?.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            ) || [];
                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="primary-category-autocomplete"
                        options={categoriesList || []}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") return option;
                          if (option.inputValue) return option.inputValue;
                          return option?.name;
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li
                              {...props}
                              key={option?.id}
                              style={{ fontWeight: 400, fontSize: "14px" }}
                            >
                              {option?.name}
                            </li>
                          );
                        }}
                        sx={{
                          width: 300,
                          fontWeight: 400,
                          fontSize: "14px",
                          minWidth: "60%",
                        }}
                        freeSolo
                        size="small"
                        renderInput={(props) => (
                          <StyledTextField
                            {...props}
                            variant="filled"
                            fullWidth
                          />
                        )}
                      />
                      <IconButton
                        onClick={() =>
                          updateFormData({
                            key: "addcategories",
                            value: formData.addcategories.filter(
                              (curentCategory: any, currentIndex: number) =>
                                currentIndex !== index
                            ),
                          })
                        }
                      >
                        <img src={close} alt="remove" height={10} />
                      </IconButton>
                    </Box>
                  )
                )}
                <ButtonOutline
                  sx={{ border: "none", my: 1 }}
                  startIcon={<img src={plusBlue} alt="plus" />}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      addcategories: [...formData.addcategories, { name: "" }],
                    })
                  }
                >
                  Add another category
                </ButtonOutline>
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    pt: 2,
                  }}
                >
                  <Typography variant="body2">Business Description</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={formData?.is_add_info_description}
                        sx={(theme) => ({ color: theme.palette.grey["300"] })}
                        onChange={(e) =>
                          updateFormData({
                            key: "is_add_info_description",
                            value: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Add additional information"
                    sx={() => ({
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                        fontWeight: 200,
                      },
                    })}
                  />
                </Box>
                <StyledTextField
                  variant="filled"
                  value={formData?.description}
                  fullWidth
                  multiline
                  minRows={5}
                  name="description"
                  onChange={handleFormData}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography variant="caption">
                    {formData?.description?.length}/750
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
                  Opening Date
                </Typography>
                <StyledTextField
                  variant="filled"
                  type="date"
                  value={formData?.openDate}
                  name="openDate"
                  onChange={handleFormData}
                />
              </Box>
              <Box
                id="contact"
                ref={sectionRefs?.contact}
                sx={(theme) => ({
                  py: 2,
                  borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                })}
              >
                <Typography variant="body1" sx={{ pb: 2 }}>
                  Contact Information
                </Typography>
                <Typography variant="body2" sx={{ pb: 1 }}>
                  Phone No
                </Typography>
                <Box>
                  <Box
                    gap={1}
                    sx={{
                      py: 1,
                      ...flexRowCenterCenter,
                      justifyContent: "flex-start",
                    }}
                  >
                    <PhoneInput
                      value={formData?.phoneNumbers}
                      onChange={(e) => {
                        setFormData({ ...formData, phoneNumbers: e });
                        try {
                          if (
                            e &&
                            parsePhoneNumber(e) &&
                            parsePhoneNumber(e)?.country
                          )
                            setFormData({
                              ...formData,
                              phoneNumbers: parsePhoneNumber(e)?.number,
                            });
                        } catch (error) {
                          console.error("Invalid country code", error);
                        }
                      }}
                    />
                  </Box>
                  {formData?.additionalPhone &&
                    formData?.additionalPhone?.map(
                      (number: string, index: number) => (
                        <Box
                          gap={1}
                          key={index}
                          sx={{
                            py: 1,
                            ...flexRowCenterCenter,
                            justifyContent: "flex-start",
                          }}
                        >
                          <PhoneInput
                            value={number}
                            onChange={(e) => {
                              const newAdditionalPhone =
                                formData?.additionalPhone?.map(
                                  (num: string, ind: number) => {
                                    if (index === ind) {
                                      return e;
                                    }
                                    return num;
                                  }
                                );
                              setFormData({
                                ...formData,
                                additionalPhone: newAdditionalPhone,
                              });
                            }}
                          />
                          <IconButton
                            onClick={() =>
                              setFormData({
                                ...formData,
                                additionalPhone:
                                  formData?.additionalPhone?.filter(
                                    (val: string, ind: number) => ind !== index
                                  ),
                              })
                            }
                          >
                            <img src={close} alt="remove" height={10} />
                          </IconButton>
                        </Box>
                      )
                    )}
                  {formData?.additionalPhone?.length < 2 && (
                    <ButtonOutline
                      sx={{ border: "none" }}
                      startIcon={<img src={plusBlue} alt="plus" />}
                      onClick={() =>
                        setFormData({
                          ...formData,
                          additionalPhone: [...formData.additionalPhone, ""],
                        })
                      }
                    >
                      Add phone number
                    </ButtonOutline>
                  )}
                </Box>

                <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
                  Website
                </Typography>
                <StyledTextField
                  variant="filled"
                  value={formData?.webSite}
                  fullWidth
                  name="webSite"
                  onChange={handleFormData}
                />
                <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
                  Short Name
                </Typography>
                <StyledTextField variant="filled" fullWidth />
                <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
                  Menu Link
                </Typography>
                <StyledTextField variant="filled" fullWidth />
              </Box>
              <Box
                id="location"
                ref={sectionRefs?.location}
                sx={(theme) => ({
                  py: 2,
                  borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                })}
              >
                <Typography variant="body1" sx={{ pb: 2 }}>
                  Location and areas
                </Typography>
                {selectedBusinessProfiles.length === 1 ? (
                  <>
                    <Typography variant="body2" sx={{ pb: 1 }}>
                      Business Location
                    </Typography>
                    <Map formData={formData} updateFormData={updateFormData} />
                  </>
                ) : (
                  <Typography variant="body2" fontWeight={400}>
                    Select individual location to view or edit business location
                    Service area and map
                  </Typography>
                )}
              </Box>
              <Box
                id="hours"
                ref={sectionRefs?.hours}
                sx={(theme) => ({
                  py: 2,
                  borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                })}
              >
                <Typography variant="body1" sx={{ pb: 1 }}>
                  Opening Hours
                </Typography>
                <WorkingHours
                  formData={formData}
                  updateFormData={updateFormData}
                />
              </Box>
              <Box
                id="attributes"
                ref={sectionRefs?.attributes}
                sx={() => ({ py: 2 })}
              >
                <Typography variant="body1" sx={{ pb: 1 }}>
                  Attributes
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={(theme) => ({
                      borderRight: `1px solid ${theme.palette.grey["200"]}`,
                      p: 2,
                      pb: 0,
                    })}
                  >
                    {steps.map((step, index) => {
                      const isActive = activeAttribute === step.id;
                      const isChecked = attributesData[
                        step.id - 1
                      ]?.options?.every(
                        (item) =>
                          item.selectedValue === "Yes" ||
                          item.selectedValue === "No"
                      );
                      return (
                        <Box
                          key={step.id}
                          onClick={() => setActiveAttribute(step.id)}
                          sx={{
                            ...flexRowCenterCenter,
                            justifyContent: "flex-start",
                            position: "relative",
                            mb: 2,
                            cursor: "pointer",
                          }}
                        >
                          {isChecked ? (
                            <Box
                              sx={{
                                ...flexRowCenterCenter,
                                boxSizing: "border-box",
                                height: "34px",
                                minHeight: 34,
                                maxHeight: 34,
                                width: "34px",
                                minWidth: 34,
                                maxWidth: 34,
                              }}
                            >
                              <img
                                src={checkedRound}
                                alt="checked"
                                sizes="24px"
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={(theme) => ({
                                ...flexRowCenterCenter,
                                height: "24px",
                                minHeight: 24,
                                maxHeight: 24,
                                width: "24px",
                                minWidth: 24,
                                maxWidth: 24,
                                border: `5px solid ${theme.palette.secondary.main}`,
                                borderRadius: "50%",
                                backgroundColor: isActive
                                  ? "#1650CF"
                                  : theme.palette.grey["300"],
                                color: !isActive
                                  ? theme.palette.text.disabled
                                  : theme.palette.secondary.main,
                                fontWeight: 600,
                                zIndex: 1,
                                fontSize: "14px",
                              })}
                            >
                              {step.id}
                            </Box>
                          )}
                          <Box
                            ml={2}
                            sx={{
                              maxWidth: 144,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color={isActive ? "primary" : "textDisabled"}
                              sx={{ fontWeight: 600 }}
                            >
                              {step.label}
                            </Typography>
                            <Typography variant="caption" color="textDisabled">
                              {step.subLabel}
                            </Typography>
                          </Box>
                          {steps.length - 1 !== index ? (
                            <Box
                              sx={{
                                position: "absolute",
                                width: "1px",
                                height: "100%",
                                backgroundColor: "lightgray",
                                top: "38px",
                                left: 17,
                                zIndex: 0,
                              }}
                            ></Box>
                          ) : null}
                        </Box>
                      );
                    })}
                  </Box>
                  {selectedAtrributeData ? (
                    <Box py={1.5} px={3}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 600, pb: 0.5 }}
                      >
                        {selectedAtrributeData.title}
                      </Typography>
                      <Typography variant="caption" color="textDisabled">
                        {selectedAtrributeData.description
                          ? selectedAtrributeData.description
                          : "No data available"}
                      </Typography>
                      {selectedAtrributeData?.options?.map((option, index) => (
                        <Box key={index} sx={{ pt: 2 }}>
                          <Typography variant="body2" sx={{ pb: 0.5 }}>
                            {option.value}
                          </Typography>
                          <RadioGroup
                            name="radio-buttons-group"
                            row
                            value={
                              option.selectedValue !== undefined
                                ? option.selectedValue
                                : null
                            }
                            onChange={(event) => {
                              const newData = attributesData?.map((data) => {
                                if (data.id === selectedAtrributeData.id) {
                                  return {
                                    ...data,
                                    options: data.options?.map((dataOption) => {
                                      if (dataOption.value === option.value) {
                                        return {
                                          ...dataOption,
                                          selectedValue: event.target.value,
                                        };
                                      }
                                      return dataOption;
                                    }),
                                  };
                                }
                                return data;
                              });
                              setAttributesData(newData);
                            }}
                          >
                            <FormControlLabel
                              sx={(theme) => ({
                                color: theme.palette.text.disabled,
                                "& .MuiTypography-root": {
                                  fontSize: "12px",
                                  fontWeight: 400,
                                },
                              })}
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              sx={(theme) => ({
                                color: theme.palette.text.disabled,
                                "& .MuiTypography-root": {
                                  fontSize: "12px",
                                  fontWeight: 400,
                                },
                              })}
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </Box>
                      ))}
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          <Divider sx={{ mt: "auto" }} />
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "flex-end",
              p: "10px 16px",
              justifySelf: "flex-end",
            }}
          >
            <Button
              sx={{ mr: 1 }}
              onClick={() => setActionOrOpen({ open: false })}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#1650CF", border: "none" }}
              onClick={saveChanges}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default Profile;
