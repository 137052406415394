import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import disableMan from "assets/images/disable-man.png";
import { Button } from "components/Buttons";
import plus from "assets/icons/add-white.svg";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { useSelector } from "react-redux";

const attributes: any = [];

const Attributes: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  const { id } = useParams();
  const { listing } = useSelector((state: RootState) => state.listingDetails);
  const [action, setAction] = useState({
    open: isEdit,
    action: isEdit ? "1" : "0",
  });

  useEffect(() => {
    if (isEdit) setAction({ open: true, action: "1" });
  }, [isEdit]);
  if (attributes.length === 0)
    return (
      <Box width="100%" height="100%" sx={{ boxSizing: "border-box" }}>
        <Box
          sx={(theme) => ({
            borderRadius: "8px",
            border: `1px solid ${theme.palette.grey["300"]}`,
            ...flexRowCenterCenter,
            textAlign: "center",
            p: 14,
          })}
        >
          <Box sx={{ maxWidth: 570 }}>
            <img src={disableMan} alt="women-post" />
            <Typography variant="h5" sx={{ pt: 3, pb: 1 }}>
              Attributes
            </Typography>
            <Typography variant="body2" color="textDisabled" sx={{ pb: 3 }}>
              Let customers know more about your business by showing attributes
              on your Business Profile. These may appear publicly on Search,
              Maps and other Google services.
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#1650CF", border: "none" }}
              startIcon={<img src={plus} alt="plus" sizes="20px" />}
              onClick={() => setAction({ action: "1", open: true })}
            >
              Add Attributes
            </Button>
          </Box>
        </Box>
        <Modal
          open={action.open || (isEdit && action.open ? true : false)}
          onClose={() => {
            setAction({ action: "0", open: false });
            if (isEdit && onClose) onClose();
          }}
        >
          <EditBusinessListing
            setActionOrOpen={({ action = "0", open = true }) => {
              setAction({ action, open });
              if (onClose) onClose();
            }}
            action={action.action}
            selectedRef="attributes"
            selectedBusiness={[{ ...listing?.overView, id }]}
          />
        </Modal>
      </Box>
    );
  return <></>;
};

export default Attributes;
