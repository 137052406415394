import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

interface UserState {
  user: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    mobile_number: string;
    connected: boolean;
  } | null;
  token: string | null;
  loading: boolean;
  error: string | null;
  googleOnboardingError: string | null;
}

const initialState: UserState = {
  user: null,
  token: null,
  loading: false,
  error: null,
  googleOnboardingError: null,
};

export const login = createAsyncThunk(
  "/login",
  async (credentials: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await apiManager.post("/login", credentials);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Login failed"
      );
    }
  }
);

export const logout = createAsyncThunk("/logout", async () => {
  try {
    localStorage.removeItem("token");
  } catch (err) {
    console.log(err);
    return "Logout failed";
  }
  return;
});

export const googleOnboarding = createAsyncThunk(
  "/google-callback",
  async (
    {
      code,
      scope,
      navigate,
    }: { code: string | null; scope: string | null; navigate: any },
    thunkAPI
  ) => {
    try {
      const state: any = thunkAPI.getState();
      const token = state.user.token;
      const response = await apiManager.get(
        `/google/callback?code=${code}&scope=${scope}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.code === 200) navigate("/insights");
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Google Login failed"
      );
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      //login
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = {
          ...action.payload.user_data,
          connected: Boolean(action.payload.isGoogleAccountAdded),
        };
        state.token = action.payload.access_token;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      //logout
      .addCase(logout.fulfilled, () => initialState)
      .addCase(logout.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      //google onboarding
      .addCase(googleOnboarding.fulfilled, (state) => {
        if (state.user) state.user.connected = true;
      })
      .addCase(googleOnboarding.rejected, (state, action) => {
        if (state.user) state.user.connected = false;
        state.googleOnboardingError = action.payload as string;
      });
  },
});

export const { resetUserState } = userSlice.actions;

export default userSlice.reducer;
