import { Box, Grid2, Typography, useMediaQuery } from "@mui/material";

import {
  ActivitiesCard,
  BarChartComponent,
  CardComponent,
  DetailsComponent,
  GaugeChart,
  KeywordTable,
  SearchAnalyticsCard,
} from "pages/Insights/Components";
import CircularProgressWithBuffer from "components/CircularProgressWithBuffer";
import { theme } from "utils/theme";
import { flexRowCenterCenter } from "utils/styles";
import InsightsLoader from "../insightsLoader";
import { useSelector } from "react-redux";
import { RootState } from "store";

const Dashboard = () => {
  const matches = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const { user } = useSelector((state: RootState) => state.user);
  if (!user?.connected) return <InsightsLoader />;
  return (
    <Grid2 container spacing={3}>
      <CardComponent
        content={
          <>
            <DetailsComponent
              label="Search Keywords"
              value="2,435,342"
              descriptionElement={
                <>
                  <Typography color="primary">120k &nbsp;</Typography>
                  searches this month
                </>
              }
            />
            <BarChartComponent />
          </>
        }
      />
      <CardComponent
        content={
          <>
            <Typography color="primary" variant="h5" sx={{ textAlign: "left" }}>
              Top 5 Keywords Performance
            </Typography>
            <Box
              sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}
            >
              <DetailsComponent
                label=""
                value="31,202"
                descriptionElement="Total volume"
              />
              <CircularProgressWithBuffer progress={45} />
            </Box>
            <KeywordTable />
          </>
        }
      />
      <CardComponent
        content={
          <>
            <DetailsComponent
              label="Views"
              value="40,240"
              descriptionElement="views this month"
            />
            <GaugeChart />
          </>
        }
      />
      {matches && <ActivitiesCard />}
      <SearchAnalyticsCard />
      {!matches && <ActivitiesCard />}
    </Grid2>
  );
};

export default Dashboard;
