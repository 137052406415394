import axios from "axios";
import { logout } from "store/auth/userSlice";

const apiManager = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  noAuth: false,
});

export const setupApiManager = (getState: any, dispatch: any) => {
  apiManager.interceptors.request.use(
    (config) => {
      const state = getState();
      const token = state.user.token;
      if (token) config.headers["Authorization"] = `Bearer ${token}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiManager.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { response } = error;
      const state = getState();
      const token = state.user.token;

      if (token && response && response.status === 401) {
        await dispatch(logout());
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );
};

export default apiManager;
