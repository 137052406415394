import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu as MenuComponent,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import TextField from "components/TextField";
import Sidebar from "components/Sidebar";
import { flexRowCenterCenter } from "utils/styles";
import { NavbarProps } from "interfaces/Components.types";
import userIcon from "assets/icons/user.svg";
import settings from "assets/icons/settings.svg";
import mapPin from "assets/icons/map-pin.svg";
import sidebarMenu from "assets/icons/sidebar-menu.svg";
import bell from "assets/icons/bell.svg";
import dots from "assets/icons/dots.svg";
import { ReactComponent as Down } from "assets/icons/down.svg";
import LogoutIcon from "assets/icons/LogoutIcon";
import Searchbar from "components/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { logout } from "store/auth/userSlice";
import { setMasterGroup } from "store/groupSlice";

const Navbar: React.FC<NavbarProps> = ({
  openDrawer,
  toggleDrawer,
  content,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openMenu, setOpenMenu] = useState(false);
  // const [search, setSearch] = useState("");
  const { user } = useSelector((state: RootState) => state.user);
  const { groups: initialGroups, masterGroup } = useSelector(
    (state: RootState) => state.groups
  );
  const [groups, setGroups] = useState<any[] | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string>("All");
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (masterGroup) setSelectedGroup(masterGroup);
  }, [masterGroup]);

  useEffect(() => {
    setGroups(initialGroups || []);
  }, [initialGroups]);

  const handleClose = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        color="secondary"
        sx={{
          borderBottom: "1px solid #e6e6e6",
          borderLeft: "1px solid #e6e6e6",
          left: openDrawer ? 284 : 72,
          flexGrow: 1,
          top: 0,
          position: "sticky",
          maxWidth: `calc(100% - ${openDrawer ? "284px" : "72px"})`,
          backgroundColor: "#ffffff",
          zIndex: 10,
        }}
      >
        <Toolbar
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              flexWrap: "wrap",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                ...flexRowCenterCenter,
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => toggleDrawer(!openDrawer)}
              >
                <img src={sidebarMenu} alt="sidebar-menu" sizes="24px" />
              </IconButton>
              <TextField
                id="select"
                size="small"
                select
                value={selectedGroup}
                onChange={(e) => dispatch(setMasterGroup(e.target.value))}
                slotProps={{
                  input: {
                    startAdornment: (
                      <img src={mapPin} alt="map-pin" sizes="16px" />
                    ),
                  },
                  select: {
                    IconComponent: (props) => (
                      <Down {...props} style={{ width: 20, fontWeight: 100 }} />
                    ),
                  },
                  inputLabel: {
                    shrink: false,
                  },
                }}
                variant="outlined"
                sx={{
                  my: 1,
                  minWidth: "220px",
                  ml: 1,
                  borderRadius: "14px",
                  "& .MuiSelect-iconOutlined": {
                    color: "#000000",
                  },
                  "& .MuiSelect-icon": {
                    top: "inherit",
                  },
                }}
              >
                <MenuItem value="All">
                  <Typography color="textDisabled" variant="body2">
                    All
                  </Typography>
                </MenuItem>
                {groups?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Typography color="textDisabled" variant="body2">
                      {item.group_name}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Searchbar sx={{ mr: 2, mt: 0 }} />
          </Box>
          <Box sx={{ ...flexRowCenterCenter, flexWrap: "wrap" }}>
            <img
              src={bell}
              alt="bell"
              sizes="24px"
              style={{ margin: "0px 8px" }}
            />
            <img
              src={dots}
              alt="dots"
              sizes="40px"
              style={{ margin: "0px 8px" }}
            />
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setOpenMenu(true);
                setAnchorEl(event.currentTarget);
              }}
              size="small"
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#444548",
                }}
              >
                <Typography variant="body2" sx={{ textTransform: "uppercase" }}>
                  {user?.first_name[0]}
                  {user?.last_name[0]}
                </Typography>
              </Avatar>
            </IconButton>{" "}
            <MenuComponent
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    minWidth: "260px",
                    top: 200,
                    "& .MuiAvatar-root": {
                      width: 40,
                      height: 40,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Box
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-start",
                  ml: 1,
                  p: 1,
                  pb: 2,
                }}
              >
                <Avatar
                  sx={{
                    backgroundColor: "#ECEFF1",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {user?.first_name[0]}
                    {user?.last_name[0]}
                  </Typography>
                </Avatar>
                <Box ml={1}>
                  <Typography variant="body1" color="primary">
                    {`${user?.first_name} ${user?.last_name}`}
                  </Typography>
                  <Typography variant="body2" color="textDisabled">
                    {user?.email}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <img src={userIcon} alt="user" width={20} height={20} />
                </ListItemIcon>
                <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
                  View profile
                </Typography>
              </MenuItem>
              <MenuItem sx={{ pb: 1 }}>
                <ListItemIcon>
                  <img src={settings} alt="settings" width={20} height={20} />
                </ListItemIcon>
                <Typography variant="body2" color="textDisabled">
                  Change Password
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  dispatch(logout());
                }}
              >
                <ListItemIcon>
                  <LogoutIcon color="#7F7F7F" />
                </ListItemIcon>
                <Typography variant="body2" color="textDisabled">
                  Logout
                </Typography>
              </MenuItem>
            </MenuComponent>
          </Box>
        </Toolbar>
      </Box>
      <Box
        sx={{
          left: openDrawer ? 284 : 72,
          maxWidth: `calc(100% - ${openDrawer ? "284px" : "72px"})`,
          position: "relative",
          minHeight: `calc(100vh - 65px)`,
          boxSizing: "border-box",
        }}
      >
        {content}
      </Box>
      <Sidebar open={openDrawer} />
    </>
  );
};

export default Navbar;
