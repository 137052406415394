import {
  Avatar,
  Box,
  Chip,
  Grid2,
  Modal,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

// import nike from "assets/images/nike.png";
import addPhoto from "assets/icons/add-photo.svg";
import shop from "assets/icons/shop.svg";
import mapPin from "assets/icons/address.svg";
import call from "assets/icons/call.svg";
import category from "assets/icons/category.svg";
import description from "assets/icons/description-overview.svg";
import website from "assets/icons/website.svg";
import clock from "assets/icons/clock.svg";
// import star from "assets/icons/star.svg";
import search from "assets/icons/search-black.svg";
import bell from "assets/icons/notification-black.svg";
import google from "assets/icons/google.svg";
import boost from "assets/icons/boost.svg";
import tick from "assets/icons/tick-circle-solid.svg";
// import arrowDownRed from "assets/icons/arrow-down-red.svg";
import { flexRowCenterCenter } from "utils/styles";
import { CircularProgressWithBufferProps } from "interfaces/Components.types";
import { Button } from "components/Buttons";
import { ReviewsCard } from "pages/Insights/Reviews";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";
import { Section } from "interfaces/BusinessProfiles.types";
import pencil from "assets/icons/pencil.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { getHealthStatus, getRatingInt } from "utils/functions";
import { sweetAlert } from "utils/sweetAlert";
import { createLogoPost } from "store/bulkActionsSlice";
import { useParams } from "react-router-dom";
import arrowUp from "assets/icons/arrow-up-green-bold.svg";
import arrowDown from "assets/icons/arrow-down-bold-red.svg";

const Overview: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  // const [coverPhoto, setCoverPhoto] = useState<string | null>(null);
  // console.log(coverPhoto);
  const { id: location_id } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const logoInputRef = useRef<HTMLInputElement>(null);
  const { listing, loading } = useSelector(
    (state: RootState) => state.listingDetails
  );
  const { status } = useSelector((state: RootState) => state.bulkActions);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (status) {
      if (status?.success)
        sweetAlert({
          title: "Success!",
          icon: "success",
          text: status?.message,
        });
      else if (!status?.success)
        sweetAlert({
          title: "Error!",
          icon: "error",
          text: status?.message || `Something went wrong!`,
        });
    }
  }, [status]);

  const handleCoverPhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const file = event.target.files?.[0];
    if (file && location_id) {
      const formData = new FormData();
      formData.append("logo", file);
      formData.append("type", type);
      formData.append("location_id", location_id);
      dispatch(createLogoPost({ req: formData, location_id }));
    }
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setCoverPhoto(reader.result as string);
    //     console.log(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  const handleButtonClick = (type: string) => {
    if (type === "cover") fileInputRef.current?.click();
    else if (type === "logo") logoInputRef.current?.click();
  };
  if (loading) return <>Loading...</>;
  else if (!listing?.overView) return <>No Data Available</>;
  return (
    <Box width="100%">
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, lg: 8 }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              margin: "auto",
              textAlign: "center",
              height: "calc(100% - 150px)",
              border: "1px solid #E6E6E6",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                height: 200,
                backgroundColor: "#efefef",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => handleButtonClick("cover")}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {listing?.profileCover
                  ?.slice(-2)
                  .find((data: any) => data?.category === "COVER")
                  ?.sourceUrl ? (
                  <Box
                    component="img"
                    src={listing?.profileCover[0]?.sourceUrl}
                    alt="Cover Photo"
                    sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : (
                  <Box>
                    <img src={addPhoto} alt="add-photo" sizes="48px" />
                    <Typography variant="body2" color="textDisabled">
                      Add Cover Photo
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={(e) => handleCoverPhotoChange(e, "COVER")}
            />
            <Avatar
              sx={{
                width: 200,
                height: 200,
                position: "relative",
                bottom: 150,
                left: 150,
                transform: "translateX(-50%)",
                border: "1px solid #DDDDDD",
                backgroundColor: "#fff",
                cursor: "pointer",
              }}
              onClick={() => handleButtonClick("logo")}
            >
              {listing?.profileCover
                ?.slice(-2)
                .find((data: any) => data?.category === "PROFILE")
                ?.sourceUrl ? (
                <img
                  alt="Business Logo"
                  src={listing?.profileCover[1]?.sourceUrl}
                  width={144}
                />
              ) : (
                <Box>
                  <img src={addPhoto} alt="add-photo" sizes="48px" />
                  <Typography variant="body2" color="textDisabled">
                    Add Logo
                  </Typography>
                </Box>
              )}
            </Avatar>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={logoInputRef}
              onChange={(e) => handleCoverPhotoChange(e, "LOGO")}
            />
            <Box
              sx={{
                position: "relative",
                top: -150,
                textAlign: "left",
              }}
            >
              <ListComponent isEdit={isEdit} onClose={onClose} />
            </Box>
          </Box>
        </Grid2>
        <Grid2
          size={{ xs: 12, lg: 4 }}
          sx={{ position: "relative", top: { xs: -150, lg: 0 } }}
        >
          <Box
            sx={(theme) => ({
              borderRadius: "8px",
              border: `1px solid ${theme.palette.grey["200"]}`,
              p: "32px 24px",
              mb: 3,
            })}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              Profile Score
            </Typography>
            <Box sx={flexRowCenterCenter}>
              <CircularProgressWithBuffer
                progress={-1.2}
                score={listing?.overView?.score}
              />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              borderRadius: "8px",
              border: `1px solid ${theme.palette.grey["200"]}`,
              p: "32px 24px",
              mb: 3,
            })}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              Platforms
            </Typography>
            <Box
              sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}
            >
              <Box sx={flexRowCenterCenter}>
                <img src={google} alt="google" sizes="24px" />
                <Typography variant="body1" sx={{ pl: 1 }}>
                  Google
                </Typography>
              </Box>
              <img src={tick} alt="tick" sizes="20px" />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              borderRadius: "8px",
              border: `1px solid ${theme.palette.grey["200"]}`,
              p: "32px 24px",
              mb: 3,
            })}
          >
            <ReviewsCard />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Overview;

const WorkingHoursComponent: React.FC<{ listing: any }> = ({ listing }) => (
  <>
    {listing?.overView?.hours
      ? listing?.overView?.hours?.map((item: any, index: number) => (
          <Box
            key={index}
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "flex-start",
              my: 1.5,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 400,
                minWidth: 80,
                pr: 4,
              }}
            >
              {item.openDay[0].toUpperCase() +
                item.openDay.slice(1).toLowerCase()}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, pr: 4, textAlign: "left", minWidth: 172 }}
            >
              {item?.openTime?.hours}:
              {item?.openTime?.minutes ? item?.openTime?.minutes : "00"} -{" "}
              {item?.closeTime?.hours}:
              {item?.closeTime?.minutes ? item?.closeTime?.minutes : "00"}
            </Typography>
          </Box>
        ))
      : null}
  </>
);

type ListComponentProps = {
  icon: string;
  title: string | React.ReactNode;
  description: React.ReactNode;
  isEdit: boolean;
  sectionRef: Section;
};

const initialListData = (listing: any) => [
  {
    icon: shop,
    title: <Typography variant="body1">Business Name</Typography>,
    description: (
      <>
        <Typography variant="h6" color="textDisabled" sx={{ py: 1 }}>
          {listing?.overView?.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h6" sx={{ pr: 1 }}>
            {listing?.overView?.averageRating}
          </Typography>
          <Rating
            value={getRatingInt(listing?.overView?.star_rating)}
            precision={0.1}
            readOnly
          />
        </Box>
      </>
    ),
    isEdit: false,
    sectionRef: "about" as Section,
  },
  {
    icon: mapPin,
    title: "Address",
    description: (
      <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
        {listing?.overView?.addressLines || "__________________"}
      </Typography>
    ),
    isEdit: false,
    sectionRef: "location" as Section,
  },
  {
    icon: call,
    title: "Contact",
    description: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <Box pr={5}>
          <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
            Primary number
          </Typography>
          <Typography variant="body1">
            {listing?.overView?.phoneNumbers?.split(",")[0]}
          </Typography>
        </Box>
        {listing?.overView?.phoneNumbers?.split(",")[1] && (
          <Box>
            <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
              Display number
            </Typography>
            <Typography variant="body1">
              {listing?.overView?.phoneNumbers?.split(",")[1] || ""}
            </Typography>
          </Box>
        )}
      </Box>
    ),
    isEdit: false,
    sectionRef: "contact" as Section,
  },
  {
    icon: category,
    title: "Category",
    description: (
      <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}>
        <Typography variant="body2" color="textDisabled" sx={{ py: 1, mr: 2 }}>
          {listing?.overView?.categoriesDisplayName}
        </Typography>
        <Chip
          label="Primary"
          sx={{ backgroundColor: "#EBF1FD", color: "#1442A7", fontWeight: 600 }}
        />
      </Box>
    ),
    isEdit: false,
    sectionRef: "about" as Section,
  },
  {
    icon: description,
    title: "Description",
    description: (
      <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
        {listing?.overView?.description}
      </Typography>
    ),
    isEdit: false,
    sectionRef: "about" as Section,
  },
  {
    icon: website,
    title: "Website",
    description: (
      <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
        {listing?.overView?.webSite}
      </Typography>
    ),
    isEdit: false,
    sectionRef: "contact" as Section,
  },
  {
    icon: clock,
    title: "Working Hours",
    description: <WorkingHoursComponent listing={listing} />,
    isEdit: false,
    sectionRef: "hours" as Section,
  },
  // {
  //   icon: star,
  //   title: "Special Hours",
  //   description: (
  //     <>
  //       <Typography variant="caption">Monday</Typography>
  //       <Typography variant="body2" sx={{ py: 1 }}>
  //         11:00 AM - 12:00 AM
  //       </Typography>
  //     </>
  //   ),
  //   isEdit: false,
  //   sectionRef: "hours" as Section,
  // },
  {
    icon: search,
    title: "Custom Store Identifier",
    description: null,
    isEdit: false,
    sectionRef: "attributes" as Section,
  },
  {
    icon: bell,
    title: "Notification contacts",
    description: null,
    isEdit: false,
    sectionRef: "attributes" as Section,
  },
];

const ListComponent: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  const [action, setAction] = useState({
    open: isEdit,
    action: isEdit ? "1" : "0",
  });
  const [selectedRef, setSelectedRef] = useState<Section>("name" as Section);
  const { listing } = useSelector((state: RootState) => state.listingDetails);
  const [listData, setListData] = useState<ListComponentProps[] | null>(null);

  const { id } = useParams();
  useEffect(() => {
    setListData(initialListData(listing));
  }, [listing]);

  const onHover = (item: ListComponentProps, hovered: boolean) => {
    const newListData = listData?.map((data) => {
      if (data.title === item.title) {
        return { ...item, isEdit: hovered ? true : false };
      }
      return { ...data, isEdit: false };
    });
    setListData(newListData || null);
  };

  useEffect(() => {
    if (isEdit) setAction({ open: true, action: "1" });
  }, [isEdit]);

  return (
    <>
      {listData?.map((item: ListComponentProps, index) => {
        return (
          <Box
            key={index}
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              p: 3,
              pb: 4,
              backgroundColor: item.isEdit
                ? theme.palette.grey["100"]
                : theme.palette.secondary.main,
              cursor: "pointer",
              borderBottom:
                listData.length - 1 === index
                  ? "none"
                  : `1px solid ${theme.palette.grey["200"]}`,
            })}
            onMouseEnter={() => onHover(item, true)}
            onMouseLeave={() => onHover(item, false)}
            onClick={async () => {
              await setSelectedRef(item.sectionRef);
              setAction({ action: "1", open: true });
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: item.description ? "flex-start" : "center",
                width: "100%",
              }}
            >
              <Avatar
                sx={(theme) => ({
                  width: 40,
                  height: 40,
                  backgroundColor: theme.palette.grey["100"],
                })}
              >
                <img src={item.icon} alt={`${item.icon}`} sizes="20px" />
              </Avatar>
              <Box
                sx={() => ({
                  pl: 2,
                  width: "100%",
                  flexGrow: 1,
                })}
              >
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1">{item?.title}</Typography>
                  {item.isEdit ? (
                    <img src={pencil} alt="edit" width="20px" height="20px" />
                  ) : null}
                </Box>
                {item.description}
              </Box>
            </Box>
          </Box>
        );
      })}
      <Modal
        open={action.open || (isEdit && action.open ? true : false)}
        onClose={() => {
          setAction({ action: "0", open: false });
          if (isEdit && onClose) onClose();
        }}
      >
        <EditBusinessListing
          setActionOrOpen={({ action = "0", open = true }) => {
            setAction({ action, open });
            if (onClose) onClose();
          }}
          action={action.action}
          selectedBusiness={[{ ...listing?.overView, id }]}
          selectedRef={selectedRef}
        />
      </Modal>
    </>
  );
};

const CircularProgressWithBuffer: React.FC<CircularProgressWithBufferProps> = ({
  progress,
  radius = 150,
  stroke = 16.5,
  score,
}) => {
  const color = Number(progress) > 0 ? "#89f4b4" : "#F48992";

  const buffer = 100 - progress;
  const size = 330;
  const circumference = Math.PI * radius;
  const strokeDashoffset = ((1 - Math.abs(progress)) / 100) * circumference;
  const bufferDashoffset = ((1 - buffer) / 100) * circumference;

  return (
    <Box position="relative" display="inline-flex" bottom={100}>
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size}
          r={radius}
          stroke="#CBCBCB"
          strokeWidth={stroke}
          fill="none"
        />
        <circle
          cx={size / 2}
          cy={size}
          r={radius}
          stroke="#CBCBCB"
          strokeWidth={stroke}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={bufferDashoffset}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
        />
      </svg>
      <svg
        width={size}
        height={size}
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        <circle
          cx={size / 2}
          cy={size}
          r={radius}
          stroke={color}
          strokeWidth={stroke}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
        />
      </svg>

      <Box
        position="absolute"
        top={320}
        left="50%"
        sx={{ transform: "translate(-50%, -50%)", textAlign: "center" }}
      >
        <Box sx={{ ...flexRowCenterCenter }}>
          {Number(progress) !== 0 && (
            <img src={Number(progress) > 0 ? arrowUp : arrowDown} alt="arrow" />
          )}
          {/* <img src={arrowDownRed} alt="arrow-down-red" sizes="16px" /> */}
          {progress && (
            <Typography
              variant="body1"
              sx={{ color: Number(progress) > 0 ? "#32ae06" : "#d41818" }}
            >
              {Math.abs(progress)}%
            </Typography>
          )}
        </Box>
        <Typography
          sx={{ fontSize: "48px" }}
        >{`${Math.round(score)}`}</Typography>
        <Typography variant="h5" color="textDisabled" sx={{ pb: 8 }}>
          {getHealthStatus(Number(score))}
        </Typography>
        <Button
          variant="contained"
          startIcon={<img src={boost} alt="boost" sizes="20px" />}
          sx={{ backgroundColor: "#5448CF", height: "48px", border: "none" }}
        >
          Boost Profile
        </Button>
      </Box>
    </Box>
  );
};
