import { Box, Divider } from "@mui/material";
import React, { forwardRef, useEffect } from "react";
import {
  bulkActionsContentStyle,
  bulkActionsInnerContentStyle,
  flexRowCenterCenter,
} from "utils/styles";
import { Button } from "components/Buttons";
import { FiltersComponent } from "pages/BusinessProfiles/BusinessProfile/Filters";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";
import { getBulkActionListing } from "store/bulkActionslistingDetailsSlice";

const Filters: React.FC<{
  setActionOrOpen: any;
  selectedBusinessProfiles: any;
}> = forwardRef(({ setActionOrOpen, selectedBusinessProfiles }, ref) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (selectedBusinessProfiles?.length === 1) {
      dispatch(getBulkActionListing(selectedBusinessProfiles[0].id));
    }
  }, [selectedBusinessProfiles]);

  return (
    <Box ref={ref} sx={bulkActionsContentStyle}>
      <Box sx={{ ...bulkActionsInnerContentStyle, pl: 2 }}>
        <FiltersComponent
          selectedBusiness={selectedBusinessProfiles}
          from="bulk"
        />
      </Box>
      <Divider sx={{ mt: "auto" }} />
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "flex-end",
          p: "10px 16px",
          justifySelf: "flex-end",
        }}
      >
        <Button onClick={() => setActionOrOpen({ open: false })}>Cancel</Button>
        {/* <Button
          variant="contained"
          sx={{ backgroundColor: "#1650CF", border: "none" }}
        >
          Save
        </Button> */}
      </Box>
    </Box>
  );
});

export default Filters;
