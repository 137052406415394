import { Avatar, Box, Grid2, Typography } from "@mui/material";
import CustomDateRangePicker from "components/DateRangePicker";
import React, { useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import userProfile from "assets/icons/user-profile.svg";
import Dot from "components/Dot";
import TextField from "components/TextField";
import { Button } from "components/Buttons";
import Searchbar from "components/Searchbar";
import { addDays } from "date-fns";

type QuestionProps = {
  id: number;
  name: string;
  store: string;
  questionTime: string;
  address: string;
  question: string;
  answers?: {
    answer: string;
    answeredBy: string;
    answerTime: string;
  }[];
  addAnswer: boolean;
  newAnswer: string;
};

const initialQuestions: QuestionProps[] = [
  {
    id: 1,
    name: "John Doe",
    store: "Nike Store | Delhi Cantt Branch | Best Shoe Showroom",
    questionTime: "4hr",
    address: "Opp. Nike Showroom Sector 67, Gurgaon Haryana 122001",
    question:
      "I have a bata formal shoe purches 2019 leather in good but shoh has gone.where from I can repare it with the original shole of Nike?",
    answers: [
      {
        answer: "Yes",
        answeredBy: "Amees agarwal",
        answerTime: "2hr",
      },
    ],
    addAnswer: false,
    newAnswer: "",
  },
  {
    id: 2,
    name: "John Doe",
    store: "Nike Store | Delhi Cantt Branch | Best Shoe Showroom",
    questionTime: "4hr",
    address: "Opp. Nike Showroom Sector 67, Gurgaon Haryana 122001",
    question:
      "I have a bata formal shoe purches 2019 leather in good but shoh has gone.where from I can repare it with the original shole of Nike?",
    answers: [
      {
        answer: "Yes",
        answeredBy: "Amees agarwal",
        answerTime: "2hr",
      },
    ],
    addAnswer: false,
    newAnswer: "",
  },
  {
    id: 3,
    name: "John Doe",
    store: "Nike Store | Delhi Cantt Branch | Best Shoe Showroom",
    questionTime: "4hr",
    address: "Opp. Nike Showroom Sector 67, Gurgaon Haryana 122001",
    question:
      "I have a bata formal shoe purches 2019 leather in good but shoh has gone.where from I can repare it with the original shole of Nike?",
    addAnswer: false,
    newAnswer: "",
  },
];

const faq = [
  { id: 1, question: "Best product for 13yr old" },
  { id: 2, question: "How to contact support?" },
  { id: 3, question: "When will we get next offer?" },
  { id: 4, question: "How to claim warranty?" },
  { id: 5, question: "Best product for 13yr old" },
  { id: 6, question: "When will we get next offer?" },
  { id: 7, question: "Best product for 13yr old" },
];

type HandleNewAnswerProps = {
  event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
  currentQuestion: QuestionProps;
  type: "add" | "change" | "cancel" | "submit";
};

const tabs = [
  { id: 1, label: "All" },
  { id: 2, label: "Replied" },
  { id: 3, label: "Unreplied" },
];

const QA = () => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [unFilteredQuestions, setUnfilteredQuestions] =
    useState(initialQuestions);
  const [questions, setQuestions] = useState(initialQuestions);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleNewAnswer = ({
    event,
    currentQuestion,
    type,
  }: HandleNewAnswerProps) => {
    const newQuestions = questions.map((question) => {
      if (question.id === currentQuestion.id) {
        if (type === "add") {
          return { ...currentQuestion, addAnswer: true };
        } else if (type === "change") {
          return {
            ...currentQuestion,
            newAnswer: event ? event?.target?.value : "",
          };
        } else if (type === "cancel") {
          return { ...currentQuestion, newAnswer: "", addAnswer: false };
        } else if (type === "submit") {
          return currentQuestion.newAnswer !== ""
            ? {
                ...currentQuestion,
                newAnswer: "",
                addAnswer: false,
                answers: [
                  ...(currentQuestion?.answers || []),
                  {
                    answer: currentQuestion.newAnswer,
                    answeredBy: "Amees agarwal",
                    answerTime: "1 sec",
                  },
                ],
              }
            : { ...currentQuestion, newAnswer: "", addAnswer: false };
        }
        return question;
      }
      return question;
    });
    setQuestions(newQuestions);
    setUnfilteredQuestions(newQuestions);
  };

  const onChangeTab = (tab: any) => {
    setActiveTab(tab);
    switch (tab.id) {
      case 1:
        setQuestions(unFilteredQuestions);
        return;
      case 2:
        setQuestions(
          unFilteredQuestions.filter((data) =>
            data?.answers ? data.answers?.length > 0 : null
          )
        );
        return;
      case 3:
        setQuestions(
          unFilteredQuestions.filter((data) => {
            return data.answers?.length === 0 || !data.answers;
          })
        );
        return;
      default:
        return;
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      sx={{ boxSizing: "border-box", textAlign: "left", p: 3 }}
    >
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
          pb: 3,
        }}
      >
        <Typography variant="h5" sx={{ whiteSpace: "nowrap" }}>
          Questions & Answers
        </Typography>
        <CustomDateRangePicker
          dateRange={dateRange}
          setDateRange={(val: any) => setDateRange(val)}
        />
      </Box>
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
          pb: 3,
        }}
      >
        <Box sx={{ ...flexRowCenterCenter }}>
          {tabs.map((tab) => (
            <Typography
              key={tab.id}
              variant="body1"
              sx={{
                borderBottom:
                  tab.id === activeTab.id ? "4px solid #440BFF" : "",
                mr: 2,
                p: 1,
                cursor: "pointer",
                boxSizing: "border-box",
              }}
              onClick={() => onChangeTab(tab)}
            >
              {tab.label}
            </Typography>
          ))}
        </Box>
        <Searchbar />
      </Box>
      {questions.length > 0 && (
        <Typography variant="h1" sx={{ pb: 3 }}>
          {questions.length} Questions
        </Typography>
      )}
      <Grid2 container gap={2}>
        <Grid2
          size={{ xs: 12, md: 7.85 }}
          sx={(theme) => ({
            border: `1px solid ${theme.palette.grey["200"]}`,
            borderRadius: "8px",
          })}
        >
          {questions.map((question, index) => (
            <Box
              key={question.id}
              sx={(theme) => ({
                display: "flex",
                p: 3,
                borderBottom:
                  index === questions.length - 1
                    ? ""
                    : `1px solid ${theme.palette.grey["200"]}`,
              })}
            >
              <Avatar
                sx={(theme) => ({
                  bgcolor: theme.palette.grey["200"],
                  width: 40,
                  height: 40,
                })}
              >
                <img src={userProfile} alt="user-profile" />
              </Avatar>
              <Box sx={{ pl: 1.5 }}>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {question.name}
                </Typography>
                <Box
                  sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}
                >
                  <Typography variant="caption" color="textDisabled">
                    {question.store}
                  </Typography>
                  <Dot />
                  <Typography variant="caption" color="textDisabled">
                    {" "}
                    Posted {question.questionTime} ago
                  </Typography>
                </Box>
                <Typography
                  color="textDisabled"
                  sx={{ fontSize: "10px", fontWeight: 400, pb: 1 }}
                >
                  {question.address}
                </Typography>
                <Box sx={{ display: "flex", pb: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 500, pr: 0.8 }}>
                    Q:
                  </Typography>
                  <Typography variant="body2">{question.question}</Typography>
                </Box>
                {question.answers ? (
                  <Box sx={{ display: "flex", pb: 3 }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, pr: 0.8 }}
                    >
                      A:
                    </Typography>
                    <Box>
                      {question?.answers?.map((currentAnswer) => (
                        <Box sx={{ pb: 1 }}>
                          <Typography variant="body2" sx={{ pb: 0.2 }}>
                            {currentAnswer.answer}
                          </Typography>
                          <Typography variant="caption" color="textDisabled">
                            By {currentAnswer.answeredBy}{" "}
                            {currentAnswer.answerTime} ago
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : null}
                <Typography
                  variant="caption"
                  sx={{ color: "#093EB1", cursor: "pointer" }}
                  onClick={() =>
                    handleNewAnswer({ currentQuestion: question, type: "add" })
                  }
                >
                  Add Answer
                </Typography>
                {question.addAnswer ? (
                  <Box>
                    <TextField
                      multiline
                      minRows={3}
                      placeholder="Type your answers..."
                      fullWidth
                      variant="outlined"
                      value={question.newAnswer}
                      onChange={(event) =>
                        handleNewAnswer({
                          event,
                          currentQuestion: question,
                          type: "change",
                        })
                      }
                    />
                    <Box
                      gap={1}
                      sx={{
                        ...flexRowCenterCenter,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleNewAnswer({
                            currentQuestion: question,
                            type: "cancel",
                          })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "#1442A7", border: "none" }}
                        onClick={() =>
                          handleNewAnswer({
                            currentQuestion: question,
                            type: "submit",
                          })
                        }
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
          ))}
        </Grid2>
        <Grid2 size={{ xs: 12, md: 3.9 }}>
          <Box
            sx={(theme) => ({
              border: `1px solid ${theme.palette.grey["200"]}`,
              borderRadius: "8px",
            })}
          >
            <Typography
              variant="h5"
              sx={(theme) => ({
                p: 3,
                borderBottom: `1px solid ${theme.palette.grey["200"]}`,
              })}
            >
              FAQ
            </Typography>
            {faq.map((question, index) => (
              <Typography
                key={question.id}
                variant="body2"
                sx={(theme) => ({
                  py: 2,
                  px: 3,
                  borderBottom:
                    questions.length - 1 === index
                      ? ""
                      : `1px solid ${theme.palette.grey["200"]}`,
                  fontWeight: 400,
                })}
              >
                {question.question}
              </Typography>
            ))}
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default QA;
