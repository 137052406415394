import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid2,
  IconButton,
  MenuItem,
  Pagination,
  Tooltip,
  Typography,
  //   useMediaQuery,
} from "@mui/material";
import { Button, ButtonOutline } from "components/Buttons";
import { DropDown } from "components/Select";
import { BusinessProfile } from "interfaces/BusinessProfiles.types";
import React, { forwardRef, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  bulkActionsContentStyle,
  bulkActionsInnerContentStyle,
  flexRowCenterCenter,
} from "utils/styles";
import TextField from "components/TextField";
import close from "assets/icons/close.svg";
import bin from "assets/icons/bin.svg";
import fileUpload from "assets/icons/file-upload.svg";
import Checkbox from "components/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import {
  createProduct,
  deleteProduct,
  editProduct,
  getProductsList,
  resetStatus,
} from "store/bulkActionsSlice";
// import { theme } from "utils/theme";

const Products: React.FC<{
  selectedBusinessProfiles: BusinessProfile[];
  setActionOrOpen: any;
}> = forwardRef(({ selectedBusinessProfiles, setActionOrOpen }, ref) => {
  // const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch<AppDispatch>();
  const { productsList } = useSelector(
    (state: RootState) => state.bulkActions.data
  );
  const { status } = useSelector((state: RootState) => state.bulkActions);
  const [tabs, setTabs] = useState([
    { id: 1, name: "Listed Products", isActive: true },
    { id: 2, name: "Edit Product", isActive: false },
    { id: 3, name: "Add Product", isActive: false },
  ]);

  const [products, setProducts] = useState<any>([]);
  const [productFilters, setProductFilters] = useState<any>([]);
  const [selectedProductFilter, setSelectedProductFilter] =
    useState<string>("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [productForm, setProductForm] = useState({
    product_title: "",
    description: "",
    productTypes: "",
    price: "",
    salePrice: "",
    product_link: "",
    is_special: false,
    post: null,
    image: null,
  });
  const [productToRemove, setProductToRemove] = useState<any>(null);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const [editProductForm, setEditProductForm] = useState<{
    id: string;
    product_title: string;
    description: string;
    productTypes: string;
    price: string;
    salePrice: string;
    product_link: string;
    is_special: boolean;
    post: string | null;
    image: any;
  }>({
    id: "",
    product_title: "",
    description: "",
    productTypes: "",
    price: "",
    salePrice: "",
    product_link: "",
    is_special: false,
    post: null,
    image: null,
  });

  const removeProduct = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (productToRemove) await dispatch(deleteProduct(productToRemove?.id));
    if (status?.success) {
      dispatch(
        getProductsList({
          filter: "",
          page: pagination.page,
          limit: pagination.limit,
        })
      );
    }
    setOpenConfirmation(false);
  };

  const onClickTab = (tab: any) => {
    if (
      (selectedBusinessProfiles?.length !== 1 ||
        (products && products?.length == 0)) &&
      tab.id === 2
    )
      return;
    setTabs(
      tabs.map((updateTab) => ({
        ...updateTab,
        isActive: updateTab.id === tab.id,
      }))
    );
  };

  useEffect(() => {
    if (selectedBusinessProfiles.length === 1) {
      dispatch(
        getProductsList({
          id: selectedBusinessProfiles[0]?.id,
          filter:
            selectedProductFilter === "all-products"
              ? ""
              : selectedProductFilter,
          page: pagination.page,
          limit: pagination.limit,
        })
      );
    }
  }, [selectedBusinessProfiles, selectedProductFilter, pagination]);

  useEffect(() => {
    if (productsList) {
      setProducts(productsList?.productList);
      setProductFilters(productsList?.product_type);
    }
  }, [productsList]);

  const updateProductForm = (key: string, value: any) => {
    setProductForm({
      ...productForm,
      [key]: value,
    });
  };

  const updateEditProductForm = (key: string, value: any) => {
    setEditProductForm({
      ...editProductForm,
      [key]: value,
    });
  };

  const saveChanges = async () => {
    if (tabs[2].isActive) {
      const payload: any = {
        ...productForm,
      };
      payload.post = payload?.image || "";
      delete payload["image"];
      const formData = new FormData();
      Object.keys(payload)?.map((key: string) => {
        formData.append(key, payload[key]);
      });
      await dispatch(
        createProduct({
          ...payload,
        })
      );
    } else if (tabs[1].isActive) {
      const payload: any = {
        ...editProductForm,
        location_id: selectedBusinessProfiles[0]?.id,
      };

      if (typeof payload?.image !== "string") {
        payload.post = payload?.image || "";
      } else delete payload["post"];
      delete payload["image"];
      const formData = new FormData();
      Object.keys(payload)?.map((key: string) => {
        formData.append(key, payload[key]);
      });
      await dispatch(
        editProduct({
          ...payload,
        })
      );
    }
    if (status?.success) {
      setActionOrOpen({ open: false });
      dispatch(resetStatus());
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({ ...pagination, page: newPage });
  };

  return (
    <Box ref={ref} sx={bulkActionsContentStyle}>
      <Box sx={bulkActionsInnerContentStyle}>
        <Box
          sx={(theme) => ({
            position: "sticky",
            top: -1,
            backgroundColor: theme.palette.secondary.main,
            zIndex: 10,
          })}
        >
          <Typography variant="body1" sx={() => ({ fontWeight: 600, p: 2 })}>
            Add Products
          </Typography>
          <Box
            gap={1}
            sx={(theme) => ({
              p: 2,
              pt: 0,
              borderBottom: `1px solid ${theme.palette.grey["200"]}`,
              display: "flex",
              flexWrap: "wrap",
            })}
          >
            {tabs.map((tab) => (
              <Typography
                component="a"
                href={`#${tab.name.toLowerCase()}-section`}
                key={tab.id}
                variant="body2"
                sx={(theme) => ({
                  textDecoration: "none",
                  borderBottom: tab.isActive ? "4px solid #440BFF" : "",
                  mr: 2,
                  p: 1,
                  cursor: "pointer",
                  boxSizing: "border-box",
                  fontWeight: 400,
                  color: tab.isActive
                    ? theme.palette.primary.main
                    : theme.palette.text.disabled,
                  whiteSpace: "nowrap",
                })}
                onClick={() => {
                  if (tab.id !== 2) onClickTab(tab);
                }}
              >
                {tab.name}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box p={2}>
          {tabs[0].isActive &&
            selectedBusinessProfiles.length === 1 &&
            products.length > 0 && (
              <Box
                gap={2}
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <DropDown
                  value={selectedProductFilter || "all-products"}
                  onChange={(e) => {
                    setPagination({ ...pagination, page: 0 });
                    setSelectedProductFilter(e.target.value as string);
                  }}
                >
                  <MenuItem
                    value="all-products"
                    sx={{ fontSize: "14px", fontWeight: 400 }}
                  >
                    All Products
                  </MenuItem>
                  {productFilters?.map((filter: any) => (
                    <MenuItem
                      key={filter?.productTypes}
                      value={filter?.productTypes}
                      sx={{ fontSize: "14px", fontWeight: 400 }}
                    >
                      {filter?.productTypes}
                    </MenuItem>
                  ))}
                </DropDown>
                <ButtonOutline
                  sx={{ borderRadius: "4px", whiteSpace: "nowrap" }}
                  onClick={() => onClickTab(tabs[2])}
                >
                  Add Product
                </ButtonOutline>
              </Box>
            )}
          {tabs[0].isActive ? (
            selectedBusinessProfiles.length === 1 && products.length > 0 ? (
              <>
                <Grid2 container gap={2} pt={2}>
                  {products?.map((product: any) => (
                    <Grid2
                      size={{ xs: 11.8, md: 5.8, lg: 3.8 }}
                      key={product.id}
                      sx={(theme) => ({
                        borderRadius: "8px",
                        border: `1px solid ${theme.palette.grey["300"]}`,
                        width: "100%",
                        cursor: "pointer",
                      })}
                      onClick={async () => {
                        onClickTab(tabs[1]);
                        setEditProductForm({
                          product_title: product?.title,
                          description: product?.description,
                          productTypes: product?.productTypes,
                          price: product?.price_value,
                          salePrice: product?.sale_price,
                          product_link: product?.product_link,
                          is_special:
                            product?.featured_product === "true" ? true : false,
                          post: product?.imageLink,
                          id: product?.id,
                          image: null,
                        });
                      }}
                    >
                      <Box
                        sx={{
                          backgroundImage: `url(${product?.imageLink})`,
                          backgroundSize: "cover",
                          borderRadius: "8px 8px 0px 0px",
                          width: "100%",
                          height: 100,
                          p: 1,
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Box>
                              <Chip
                                sx={(theme) => ({
                                  backgroundColor: theme.palette.secondary.main,
                                  fontSize: "10px",
                                  textTransform: "uppercase",
                                  height: 20,
                                  mb: 1,
                                })}
                                label={product?.product_status}
                              />
                            </Box>
                            {product.featured_product === "true" ? (
                              <Chip
                                sx={(theme) => ({
                                  backgroundColor: theme.palette.grey["700"],
                                  color: theme.palette.secondary.main,
                                  fontSize: "10px",
                                  fontWeight: 600,
                                  height: 20,
                                  width: 60,
                                  borderRadius: "3px",
                                  textTransform: "capitalize",
                                })}
                                label="Special"
                              />
                            ) : null}
                          </Box>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setProductToRemove(product);
                              setOpenConfirmation(true);
                            }}
                            sx={(theme) => ({
                              height: 15,
                              width: 15,
                              backgroundColor: theme.palette.secondary.main,
                            })}
                          >
                            <img
                              src={close}
                              alt="close"
                              style={{ width: 14, height: 14 }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          p: 1,
                          boxSizing: "border-box",
                          maxWidth: "100%",
                          top: -100,
                        }}
                      >
                        <Tooltip title={product?.title}>
                          <Typography
                            variant="caption"
                            sx={{
                              maxWidth: "100%",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {product?.title || "___________________"}
                          </Typography>
                        </Tooltip>
                        <Tooltip title={product?.description}>
                          <Typography
                            variant="caption"
                            color="textDisabled"
                            sx={{
                              maxWidth: "100%",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {product?.description || "___________________"}
                          </Typography>
                        </Tooltip>
                        <Box pt={2}>
                          {(product?.saleprice || product?.price_value) && (
                            <Typography
                              variant="caption"
                              color="success"
                              mr={2}
                            >
                              Rs.{" "}
                              {Number(
                                product?.sale_price
                                  ? product?.sale_price
                                  : product?.price_value
                              )?.toFixed(2)}
                            </Typography>
                          )}
                          {product?.sale_price && (
                            <Typography
                              variant="caption"
                              color="textDisabled"
                              sx={{ textDecoration: "line-through" }}
                            >
                              Rs. {product?.price_value}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Dialog
                        sx={{
                          "& .MuiDialog-paper": {
                            width: 300,
                            borderRadius: "11px",
                            textAlign: "center",
                          },
                        }}
                        maxWidth="xs"
                        open={openConfirmation}
                      >
                        <DialogTitle>Warning</DialogTitle>
                        <DialogContent>
                          <Typography variant="body2">
                            Once the product listing is deleted, cannot be
                            reverted
                          </Typography>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
                          <Button
                            autoFocus
                            sx={{ mr: 4 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenConfirmation(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              border: "none",
                              backgroundColor: "rgba(22, 80, 207, 1)",
                            }}
                            onClick={(e) => removeProduct(e)}
                          >
                            Proceed
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid2>
                  ))}
                </Grid2>
                <Box
                  gap={2}
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    pt: 4,
                  }}
                >
                  <Box
                    gap={2}
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <DropDown
                      label="Rows per rage"
                      width={100}
                      onChange={(e: any) =>
                        setPagination({
                          ...pagination,
                          limit: e.target.value,
                        })
                      }
                      value={pagination.limit}
                    >
                      {[10, 50, 100].map((rows, index) => (
                        <MenuItem
                          value={rows}
                          key={index}
                          sx={{ fontWeight: 500, fontSize: "14px" }}
                        >
                          {rows}
                        </MenuItem>
                      ))}
                    </DropDown>
                    <Typography variant="body2">
                      {productsList?.totalCount[0]?.count &&
                        `Showing
                      ${products?.length}
                      of ${productsList?.totalCount[0]?.count}`}
                    </Typography>
                  </Box>
                  <Pagination
                    count={
                      productsList?.totalCount[0]?.count
                        ? productsList?.totalCount[0]?.count %
                            pagination?.limit !==
                          0
                          ? Math.round(
                              productsList?.totalCount[0]?.count /
                                pagination?.limit
                            ) + 1
                          : Math.round(
                              productsList?.totalCount[0]?.count /
                                pagination?.limit
                            )
                        : 0
                    }
                    variant="outlined"
                    shape="rounded"
                    page={pagination?.page}
                    onChange={handleChangePage}
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "#222124",
                      },
                      "& .MuiPaginationItem-root.Mui-selected": {
                        color: "#1976d2",
                        borderColor: "#1976d2",
                        backgroundColor: "#ffffff",
                        fontWeight: 500,
                      },
                      "& .MuiPaginationItem-root:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                  />
                </Box>
              </>
            ) : (
              <Box sx={{ textAlign: "center", pt: 2 }}>
                <Typography variant="body2" sx={{ pb: 2 }}>
                  Select individual listing to see the listed products
                </Typography>
                <Typography variant="body2" sx={{ pb: 2 }}>
                  Or
                </Typography>
                <Typography variant="body2" sx={{ pb: 2 }}>
                  No product listed
                </Typography>
                <Button
                  sx={{
                    color: "#1650CF",
                    border: "1px solid #1650CF",
                    width: 120,
                    mb: 2,
                  }}
                  onClick={() => onClickTab(tabs[2])}
                >
                  Add new
                </Button>
              </Box>
            )
          ) : tabs[1].isActive ? (
            <ProductForm
              productForm={editProductForm}
              updateProductForm={updateEditProductForm}
              productFilters={productFilters}
            />
          ) : (
            <ProductForm
              productForm={productForm}
              updateProductForm={updateProductForm}
              productFilters={productFilters}
            />
          )}
        </Box>
      </Box>
      <Box>
        <Divider sx={{ mt: "auto" }} />
        <Box
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "flex-end",
            p: "10px 16px",
            justifySelf: "flex-end",
          }}
        >
          <Button
            sx={{ mr: 1 }}
            onClick={() => {
              dispatch(resetStatus());
              setActionOrOpen({ open: false });
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#1650CF", border: "none" }}
            onClick={saveChanges}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
});

const ProductForm: React.FC<{
  productForm: any;
  updateProductForm: (key: string, value: any) => void;
  productFilters: any;
}> = ({ productForm, updateProductForm, productFilters }) => {
  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles) updateProductForm("image", acceptedFiles[0]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [addNewCategory, setAddNewCategory] = useState(false);

  return (
    <Grid2 gap={3} container>
      <Grid2 size={{ xs: 12, lg: 6.7 }}>
        <Box sx={{ width: "100%", minWidth: 250 }}>
          <Typography variant="body2">Product Name</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            sx={{ mb: 0.2 }}
            value={productForm?.product_title}
            name="product_title"
            onChange={(e) =>
              updateProductForm("product_title", e.target.value.slice(0, 58))
            }
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="caption">
              {productForm?.product_title?.length || 0}/58
            </Typography>
          </Box>
          <Typography variant="body2" mb={1}>
            Select a category
          </Typography>
          <Box mb={2}>
            <DropDown
              label="Select"
              labelVisible={!productForm?.productTypes}
              width="100%"
              name="productTypes"
              value={productForm?.productTypes}
              onChange={(e) => {
                if (e.target.value === "new-category") {
                  setAddNewCategory(true);
                  updateProductForm("productTypes", "");
                } else {
                  setAddNewCategory(false);
                  updateProductForm("productTypes", e.target.value);
                }
              }}
            >
              <MenuItem
                key="new-category"
                value="new-category"
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >
                Create a new category
              </MenuItem>
              {productFilters?.map((filter: any) => (
                <MenuItem
                  key={filter?.productTypes}
                  value={filter?.productTypes}
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                >
                  {filter?.productTypes}
                </MenuItem>
              ))}
            </DropDown>
          </Box>
          {addNewCategory && (
            <>
              <Typography variant="body2">Product category</Typography>
              <TextField
                variant="outlined"
                name="productTypes"
                fullWidth
                size="small"
                value={productForm?.productTypes}
                onChange={(e) =>
                  updateProductForm("productTypes", e.target.value)
                }
              />
            </>
          )}
          <Typography variant="body2">
            Product price (INR) - optional
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            name="price"
            size="small"
            value={productForm?.price}
            onChange={(e) => updateProductForm("price", e.target.value)}
          />
          <Typography variant="body2">
            Product discount price (INR) - optional
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            name="salePrice"
            size="small"
            value={productForm?.salePrice}
            onChange={(e) => updateProductForm("salePrice", e.target.value)}
          />
          <Typography variant="body2">
            Product description - optional
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            name="description"
            size="small"
            sx={{ mb: 0.2 }}
            multiline
            minRows={3}
            value={productForm?.description}
            onChange={(e) =>
              updateProductForm("description", e.target.value.slice(0, 1000))
            }
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="caption">
              {productForm?.description?.length || 0}/1000
            </Typography>
          </Box>
          <Typography variant="body2">
            Product landing page URL - optional
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            name="product_link"
            size="small"
            sx={{ mb: 0.2 }}
            value={productForm?.product_link}
            onChange={(e) =>
              updateProductForm("product_link", e.target.value.slice(0, 1500))
            }
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="caption">
              {productForm?.product_link?.length || 0}/1,500
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  value={productForm?.is_special}
                  name="is_special"
                  onChange={(e) =>
                    updateProductForm("is_special", e.target.checked)
                  }
                />
              }
              label={
                <Box maxWidth={220}>
                  <Typography sx={{ fontSize: "12px" }}>
                    Mark as Special
                  </Typography>
                  <Typography variant="caption" color="textDisabled">
                    Product marked as Special as shown on the top of the page
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>
      </Grid2>
      <Grid2 size={{ xs: 12, lg: 4.7 }}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={(theme) => ({
              borderRadius: "15px",
              border: `1px solid ${theme.palette.grey["400"]}`,
            })}
          >
            {productForm?.post ? (
              <Box
                sx={{
                  width: "100%",
                  height: "200px",
                  backgroundImage: `url(${productForm?.post})`,
                  borderRadius: "15px",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton
                  sx={(theme) => ({
                    backgroundColor: theme.palette.grey["100"],
                  })}
                  onClick={() => updateProductForm("post", null)}
                >
                  <img src={bin} alt="bin" height="20px" width="20px" />
                </IconButton>
              </Box>
            ) : (
              //   <img
              //     src={productForm?.post}
              //     alt="image"
              //     width="100%"
              //     height="100%"
              //     style={{ borderRadius: "15px" }}
              //   />
              <Box
                {...getRootProps()}
                sx={() => ({
                  textAlign: "center",
                  width: "100%",
                  p: 3,
                  boxSizing: "border-box",
                })}
              >
                <input {...getInputProps()} accept="image/*, video/*" />
                <img src={fileUpload} alt="file-upload" sizes="32px" />
                <Box sx={{ ...flexRowCenterCenter }}>
                  <Typography
                    variant="caption"
                    sx={{ my: 1, whiteSpace: "none" }}
                  >
                    Drag and Drop or{" "}
                  </Typography>
                  <Button
                    variant="text"
                    component="label"
                    sx={{
                      textTransform: "none",
                      border: "none",
                      color: "#1650CF",
                      fontSize: "12px",
                      whiteSpace: "none",
                      pl: 0.5,
                      pr: 0,
                    }}
                  >
                    Click to upload
                  </Button>
                </Box>
                <Typography variant="caption" color="textDisabled">
                  Supported formats: Images or videos. Max Size: 25MB
                </Typography>
                {productForm?.image && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected file: {productForm?.image?.name}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Products;
