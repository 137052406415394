import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import plus from "assets/icons/add-black.svg";
import plusBlue from "assets/icons/plus-blue.svg";
import info from "assets/icons/info.svg";
import bin from "assets/icons/bin.svg";
import dropdown from "assets/icons/dropdown.svg";
import bluePlus from "assets/icons/plus-blue.svg";
import Checkbox from "components/Checkbox";
import Radio from "components/Radio";
import Select from "components/Select";
import {
  GoogleMap,
  MarkerF as Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { Button, ButtonOutline } from "components/Buttons";
import {
  StyledTextField as CustomTextField,
  initialWorkingHoursData,
} from "../Bulkactions/Profile";
import { ReactComponent as DownIcon } from "assets/icons/down.svg";
import Toggle from "components/Toggle";
import { flexRowCenterCenter } from "utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { hoursArrayToObject } from "utils/functions";
import { addDays, format } from "date-fns";
import { getHoursList, getStatesList } from "store/bulkActionsSlice";

type HoursType = "regular" | "special" | "more";

const hoursOptions = [
  { id: "1", time: "24 hours" },
  { id: "2", time: "00:00" },
  { id: "3", time: "00:30" },
  { id: "4", time: "01:00" },
  { id: "5", time: "01:30" },
  { id: "6", time: "02:00" },
  { id: "7", time: "02:30" },
  { id: "8", time: "03:00" },
  { id: "9", time: "03:30" },
  { id: "10", time: "04:00" },
  { id: "11", time: "04:30" },
  { id: "12", time: "05:00" },
  { id: "13", time: "05:30" },
  { id: "14", time: "06:00" },
  { id: "15", time: "06:30" },
  { id: "16", time: "07:00" },
  { id: "17", time: "07:30" },
  { id: "18", time: "08:00" },
  { id: "19", time: "08:30" },
  { id: "20", time: "09:00" },
  { id: "21", time: "09:30" },
  { id: "22", time: "10:00" },
  { id: "23", time: "10:30" },
  { id: "24", time: "11:00" },
  { id: "25", time: "11:30" },
  { id: "26", time: "12:00" },
  { id: "27", time: "12:30" },
  { id: "28", time: "13:00" },
  { id: "29", time: "13:30" },
  { id: "30", time: "14:00" },
  { id: "31", time: "14:30" },
  { id: "32", time: "15:00" },
  { id: "33", time: "15:30" },
  { id: "34", time: "16:00" },
  { id: "35", time: "16:30" },
  { id: "36", time: "17:00" },
  { id: "37", time: "17:30" },
  { id: "38", time: "18:00" },
  { id: "39", time: "18:30" },
  { id: "40", time: "19:00" },
  { id: "41", time: "19:30" },
  { id: "42", time: "20:00" },
  { id: "43", time: "20:30" },
  { id: "44", time: "21:00" },
  { id: "45", time: "21:30" },
  { id: "46", time: "22:00" },
  { id: "47", time: "22:30" },
  { id: "48", time: "23:00" },
  { id: "49", time: "23:30" },
  { id: "50", time: "24 hours" },
];

export const WorkingHours: React.FC<{
  formData: any;
  updateFormData: ({ key, value }: { key: string; value: any }) => void;
}> = ({ formData, updateFormData }) => {
  const workingHoursData = formData?.hours;
  const moreHoursData = formData?.moreHour;
  const specialHoursData = formData?.specialHours;
  const { data } = useSelector((state: RootState) => state.bulkActions);
  const dispatch = useDispatch<AppDispatch>();
  const [hoursList, setHoursList] = useState<any>();
  const [specialHoursAction, setSpecialHoursAction] =
    React.useState<null | HTMLElement>(null);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const open = Boolean(specialHoursAction);
  const handleSpecialHoursActionClick = (
    item: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedDay(item);
    setSpecialHoursAction(event.currentTarget);
  };
  const handleSpecialHoursActionClose = () => {
    setSpecialHoursAction(null);
    setSelectedDay(null);
  };

  useEffect(() => {
    dispatch(getHoursList());
  }, []);

  useEffect(() => {
    if (data.hoursList) setHoursList(data.hoursList);
  }, [data.hoursList]);

  const handleDateChange = ({
    event,
    day,
  }: {
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    day: string;
  }) => {
    const isExists = Object.keys(specialHoursData)
      .filter((date: string) => date !== day)
      .includes(event.target.value);
    if (!isExists) {
      setDateError(null);
      const newSpecialHoursData: any = {};
      Object.keys(specialHoursData).forEach((date: string) => {
        if (date === day) {
          newSpecialHoursData[event.target.value] = specialHoursData[date];
        } else {
          newSpecialHoursData[date] = specialHoursData[date];
        }
      });
      updateFormData({ key: "specialHours", value: newSpecialHoursData });
    } else setDateError(day);
  };

  const deleteDay = () => {
    const newSpecialHoursData = { ...specialHoursData };
    if (selectedDay) {
      delete newSpecialHoursData[selectedDay];
      updateFormData({ key: "specialHours", value: newSpecialHoursData });
    }
  };

  const addDay = () => {
    const specialHoursKeys = specialHoursData
      ? Object.keys(specialHoursData)
      : [];
    const lastKey = specialHoursKeys
      ? specialHoursKeys[specialHoursKeys.length - 1]
      : null;
    const newDate = lastKey ? addDays(new Date(lastKey), 1) : new Date();
    const newSpecialHoursData = {
      ...specialHoursData,
      [format(newDate, "yyyy-MM-dd")]: [
        {
          startDate: {
            day: newDate.getDay,
            year: newDate.getDay,
            month: newDate.getMonth,
          },
          endDate: {
            day: newDate.getDay,
            year: newDate.getDay,
            month: newDate.getMonth,
          },
          closeTime: { hours: 0 },
          openTime: { hours: 0 },
        },
      ],
    };
    updateFormData({ key: "specialHours", value: newSpecialHoursData });
  };

  const deleteTime = ({
    day,
    index,
    type,
    hoursTypeId,
  }: {
    day: keyof typeof workingHoursData;
    index: number;
    type: HoursType;
    hoursTypeId?: string;
  }) => {
    const setDay = (data: any, day: any, index: number) => {
      const newData = { ...data };
      newData[day].splice(index, 1);
      return newData;
    };

    if (type === "regular") {
      const newWorkingHoursData = setDay(workingHoursData, day, index);
      updateFormData({ key: "hours", value: newWorkingHoursData });
    } else if (type === "more" && hoursTypeId) {
      const newMoreHours = moreHoursData?.map((data: any) => {
        if (data?.hoursTypeId === hoursTypeId) {
          const newPeriods = setDay(data?.periods, day, index);
          return { ...data, periods: newPeriods };
        }
        return data;
      });
      updateFormData({ key: "moreHour", value: newMoreHours });
    } else if (type === "special") {
      const newSpecialHoursData = setDay(specialHoursData, day, index);
      updateFormData({ key: "specialHours", value: newSpecialHoursData });
    }
  };

  const addTime = ({
    day,
    type,
    hoursTypeId,
  }: {
    day: keyof typeof workingHoursData;
    type: HoursType;
    hoursTypeId?: string;
    dayObject?: any;
  }) => {
    const setDay = (data: any, day: any) => {
      const newData = { ...data };
      newData[day].push({
        openDay: day,
        closeDay: day,
        openTime: { hours: 24, minutes: 0 },
        closeTime: { hours: 24, minutes: 0 },
      });
      return newData;
    };

    if (type === "regular") {
      const newWorkingHoursData = setDay(workingHoursData, day);
      updateFormData({ key: "hours", value: newWorkingHoursData });
    } else if (type === "more" && hoursTypeId) {
      const newMoreHoursData = moreHoursData.map((data: any) => {
        if (data?.hoursTypeId === hoursTypeId) {
          const newPeriods = setDay(data?.periods, day);
          return { ...data, periods: newPeriods };
        }
        return data;
      });
      updateFormData({ key: "moreHour", value: newMoreHoursData });
    } else if (type === "special" && selectedDay) {
      const newSpecialHoursData = { ...specialHoursData };
      newSpecialHoursData[selectedDay].push({
        startDate: specialHoursData[day][0]?.startDate,
        endDate: specialHoursData[day][0]?.endDate,
        openTime: { hours: 24, minutes: 0 },
        closeTime: { hours: 24, minutes: 0 },
      });
      updateFormData({ key: "specialHours", value: newSpecialHoursData });
    }
  };

  const toggleClose = ({
    e,
    day,
    hoursType,
    hoursTypeId,
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    day: keyof typeof workingHoursData;
    hoursType: HoursType;
    hoursTypeId?: string;
  }) => {
    const setDay = (val: boolean, day: any) => {
      if (val)
        return [
          {
            openDay: day,
            closeDay: day,
            openTime: { hours: 0 },
            closeTime: { hours: 0 },
          },
        ];
      else
        return [
          {
            openDay: day,
            closeDay: day,
            openTime: { hours: 9 },
            closeTime: { hours: 17 },
          },
        ];
    };
    if (hoursType === "regular") {
      const newWorkingHoursData = { ...workingHoursData };
      newWorkingHoursData[day] = setDay(e.target.checked, day);
      updateFormData({ key: "hours", value: newWorkingHoursData });
    } else if (hoursType === "more" && hoursTypeId) {
      const newMoreHoursData = moreHoursData?.map((data: any) => {
        if (data?.hoursTypeId === hoursTypeId) {
          const newPeriods = { ...data?.periods };
          newPeriods[day] = setDay(e.target.checked, day);
          return { ...data, periods: newPeriods };
        }
        return data;
      });
      updateFormData({ key: "moreHour", value: newMoreHoursData });
    } else if (hoursType === "special") {
      const newSpecialHoursData = { ...specialHoursData };
      newSpecialHoursData[day] = e.target.checked
        ? [
            {
              startDate: specialHoursData[day][0]?.startDate,
              endDate: specialHoursData[day][0]?.endDate,
              openTime: { hours: 0 },
              closeTime: { hours: 0 },
            },
          ]
        : [
            {
              startDate: specialHoursData[day][0]?.startDate,
              endDate: specialHoursData[day][0]?.endDate,
              openTime: { hours: 9 },
              closeTime: { hours: 17 },
            },
          ];
      updateFormData({ key: "specialHours", value: newSpecialHoursData });
    }
  };

  const onChangeTime = ({
    e,
    day,
    index,
    type,
    hoursType,
    hoursTypeId,
  }: {
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    day: any;
    index: number;
    type: "open" | "close";
    hoursType: HoursType;
    hoursTypeId?: string;
  }) => {
    if (hoursType === "regular") {
      console.log(e.target.value);
      const newWorkingHoursData = JSON.parse(JSON.stringify(workingHoursData));
      const timeSlot = newWorkingHoursData[day][index];
      const value = e.target.value;
      if (value === "24 hours") {
        newWorkingHoursData[day] = [
          {
            openTime: {},
            closeTime: { hours: 24 },
            closeDay: day,
            openDay: day,
          },
        ];
      } else if (type === "open") {
        const [hours, minutes = 0] = value.split(":").map(Number);
        timeSlot.openTime = { hours, minutes };
      } else if (type === "close") {
        const [hours, minutes = 0] = value.split(":").map(Number);
        timeSlot.closeTime = { hours, minutes };
      }
      updateFormData({ key: "hours", value: { ...newWorkingHoursData } });
    } else if (hoursType === "more") {
      const updatedMoreHoursData = moreHoursData?.map((data: any) => {
        if (data?.hoursTypeId === hoursTypeId) {
          const newPeriods = JSON.parse(JSON.stringify(data?.periods));
          const timeSlot = newPeriods[day][index];
          const value = e.target.value;
          if (value === "24 hours") {
            newPeriods[day] = [
              {
                openTime: {},
                closeTime: { hours: 24 },
                closeDay: day,
                openDay: day,
              },
            ];
          } else if (type === "open") {
            const [hours, minutes = 0] = value.split(":").map(Number);
            timeSlot.openTime = { hours, minutes };
          } else if (type === "close") {
            const [hours, minutes = 0] = value.split(":").map(Number);
            timeSlot.closeTime = { hours, minutes };
          }
          return {
            ...data,
            periods: newPeriods,
          };
        }
        return data;
      });
      updateFormData({ key: "moreHour", value: updatedMoreHoursData });
    } else if (hoursType === "special") {
      const newSpecialHoursData = JSON.parse(JSON.stringify(specialHoursData));
      const timeSlot = newSpecialHoursData[day][index];
      const value = e.target.value;
      if (value === "24 hours") {
        newSpecialHoursData[day] = [
          {
            openTime: {},
            closeTime: { hours: 24 },
            closeDay: day,
            openDay: day,
          },
        ];
      } else if (type === "open") {
        const [hours, minutes = 0] = value.split(":").map(Number);
        timeSlot.openTime = { hours, minutes };
      } else if (type === "close") {
        const [hours, minutes = 0] = value.split(":").map(Number);
        timeSlot.closeTime = { hours, minutes };
      }
      updateFormData({
        key: "specialHours",
        value: { ...newSpecialHoursData },
      });
    }
  };

  const addMoreHour = ({ hour }: { hour: any }) => {
    updateFormData({
      key: "moreHour",
      value: [
        ...moreHoursData,
        {
          hoursTypeId: hour?.name,
          periods: hoursArrayToObject([]),
        },
      ],
    });
  };

  return (
    <Box>
      <Typography variant="body2" sx={{ py: 1, fontWeight: 500 }}>
        Hours
      </Typography>
      <Typography variant="body2" sx={{ pb: 2 }}>
        Set main business hours or mark your business as closed.
      </Typography>
      <RadioGroup
        name="hours-group"
        value={formData?.openInfo !== undefined ? formData?.openInfo : null}
        onChange={(event) => {
          updateFormData({ key: "openInfo", value: event.target.value });
        }}
      >
        <Box sx={{ display: "flex", mb: 2 }}>
          <Radio value="OPEN1" id="1" />
          <Box>
            <Typography
              component="label"
              htmlFor="1"
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Open with main hours
            </Typography>
            <br />
            <Typography
              component="label"
              htmlFor="1"
              variant="caption"
              sx={{ cursor: "pointer" }}
            >
              Show when your business is open
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Radio value="OPEN2" id="2" />
          <Box>
            <Typography
              component="label"
              htmlFor="2"
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Open with no main hours
            </Typography>
            <br />
            <Typography
              component="label"
              htmlFor="2"
              variant="caption"
              sx={{ cursor: "pointer" }}
            >
              Don’t show any business hours
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Radio value="CLOSED_TEMPORARILY" id="3" />
          <Box>
            <Typography
              component="label"
              htmlFor="3"
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Temporarily closed
            </Typography>
            <br />
            <Typography
              component="label"
              htmlFor="3"
              variant="caption"
              sx={{ cursor: "pointer" }}
            >
              Show that your business will open again in the future
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Radio value="CLOSED_PERMANENTLY" id="4" />
          <Box>
            <Typography
              component="label"
              htmlFor="4"
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Permanently closed
            </Typography>
            <br />
            <Typography
              component="label"
              htmlFor="4"
              variant="caption"
              sx={{ cursor: "pointer" }}
            >
              Show that your business no longer exists
            </Typography>
          </Box>
        </Box>
      </RadioGroup>
      {formData?.openInfo === "OPEN1" && (
        <TableContainer sx={{ pt: 2, maxWidth: 490 }}>
          <Table sx={{ border: "none" }}>
            <TableBody>
              {Object.keys(workingHoursData)?.map((item: any, i: number) => {
                return (
                  <React.Fragment key={i}>
                    {(workingHoursData[item]
                      ? workingHoursData[item]
                      : initialWorkingHoursData[
                          item as keyof typeof initialWorkingHoursData
                        ]
                    )?.map((time: any, j: number) => {
                      const isNotClosed =
                        time?.openTime?.hours ||
                        time?.openTime?.minutes ||
                        time?.closeTime?.hours ||
                        time?.closeTime?.minutes;
                      const is24Hours =
                        !time?.openTime?.hours &&
                        !time?.openTime?.minutes &&
                        time?.closeTime?.hours == 24 &&
                        !time?.closeTime?.minutes;

                      return (
                        <TableRow key={j}>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              p: 0.1,
                              width: 180,
                              py: 1,
                            }}
                          >
                            {j === 0 ? (
                              <Box>
                                <Typography variant="body2">
                                  {time?.openDay}
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        toggleClose({
                                          e,
                                          day: item,
                                          hoursType: "regular",
                                        })
                                      }
                                      checked={!isNotClosed}
                                    />
                                  }
                                  label="Closed"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontWeight: 400,
                                      fontSize: "14px",
                                    },
                                  }}
                                />
                              </Box>
                            ) : (
                              <Box></Box>
                            )}
                          </TableCell>
                          {!is24Hours && isNotClosed ? (
                            <>
                              <TableCell sx={{ borderBottom: "none", p: 0.1 }}>
                                <Select
                                  label="Opens at"
                                  value={`${String(time?.openTime?.hours == 24 ? 0 : time?.openTime?.hours).padStart(2, "0")}:${time?.openTime?.minutes ? time?.openTime?.minutes : "00"}`}
                                  slotProps={{
                                    inputLabel: {
                                      shrink: true,
                                      style: { color: "#424242" },
                                    },
                                  }}
                                  sx={{ minWidth: 120 }}
                                  onChange={(e) => {
                                    onChangeTime({
                                      e,
                                      day: item,
                                      index: j,
                                      type: "open",
                                      hoursType: "regular",
                                    });
                                  }}
                                >
                                  {hoursOptions.map((hour) => (
                                    <MenuItem
                                      key={hour.id}
                                      value={hour.time}
                                      sx={{ fontWeight: 400 }}
                                    >
                                      {hour.time}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>
                              <TableCell sx={{ borderBottom: "none", p: 0.1 }}>
                                <Select
                                  label="Closes at"
                                  value={`${String(time?.closeTime?.hours == 24 ? 0 : time?.closeTime?.hours).padStart(2, "0")}:${time?.closeTime?.minutes ? time?.closeTime?.minutes : "00"}`}
                                  slotProps={{
                                    inputLabel: {
                                      shrink: true,
                                      style: { color: "#424242" },
                                    },
                                  }}
                                  sx={{ minWidth: 120 }}
                                  onChange={(e) => {
                                    onChangeTime({
                                      e,
                                      day: item,
                                      index: j,
                                      type: "close",
                                      hoursType: "regular",
                                    });
                                  }}
                                >
                                  {hoursOptions.map((hour) => (
                                    <MenuItem
                                      key={hour.id}
                                      value={hour.time}
                                      sx={{ fontWeight: 400 }}
                                    >
                                      {hour.time}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>
                              <TableCell sx={{ borderBottom: "none", p: 0.1 }}>
                                {j === 0 ? (
                                  time?.openTime?.hours == 0 ||
                                  time?.openTime?.hours ||
                                  time?.openTime?.minutes == 0 ||
                                  time?.openTime?.minutes ? (
                                    <IconButton
                                      onClick={() =>
                                        addTime({ day: item, type: "regular" })
                                      }
                                    >
                                      <img
                                        src={plus}
                                        alt="plus"
                                        width={24}
                                        height={24}
                                      />
                                    </IconButton>
                                  ) : null
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      deleteTime({
                                        day: item,
                                        index: j,
                                        type: "regular",
                                      })
                                    }
                                  >
                                    <img
                                      src={bin}
                                      alt="bin"
                                      width={24}
                                      height={24}
                                    />
                                  </IconButton>
                                )}
                              </TableCell>
                            </>
                          ) : is24Hours ? (
                            <TableCell sx={{ borderBottom: "none", p: 0.1 }}>
                              <Select
                                label="Opens at"
                                value={"24 hours"}
                                slotProps={{
                                  inputLabel: {
                                    shrink: true,
                                    style: { color: "#424242" },
                                  },
                                }}
                                sx={{ minWidth: 120 }}
                                onChange={(e) => {
                                  onChangeTime({
                                    e,
                                    day: item,
                                    index: j,
                                    type: "open",
                                    hoursType: "regular",
                                  });
                                }}
                              >
                                {hoursOptions.map((hour) => (
                                  <MenuItem
                                    key={hour.id}
                                    value={hour.time}
                                    sx={{ fontWeight: 400 }}
                                  >
                                    {hour.time}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Divider sx={{ my: 1 }} />
      <Typography variant="body2" sx={{ py: 1, fontWeight: 500 }}>
        Special Hours
      </Typography>
      <Typography variant="body2" sx={{ pb: 2 }}>
        Add hours so customers know when you're open
      </Typography>
      {specialHoursData && (
        <TableContainer sx={{ pt: 2, maxWidth: 490 }}>
          <Table sx={{ border: "none" }}>
            <TableBody>
              {Object.keys(specialHoursData)?.map((item: any, i: number) => {
                return (
                  <React.Fragment key={i}>
                    {specialHoursData[item]?.map((time: any, j: number) => {
                      const isNotClosed =
                        time?.openTime?.hours ||
                        time?.openTime?.minutes ||
                        time?.closeTime?.hours ||
                        time?.closeTime?.minutes;
                      const is24Hours =
                        !time?.openTime?.hours &&
                        !time?.openTime?.minutes &&
                        time?.closeTime?.hours == 24 &&
                        !time?.closeTime?.minutes;
                      return (
                        <TableRow key={j} sx={{ verticalAlign: "top" }}>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              p: 0.1,
                              width: 180,
                              py: 1,
                            }}
                          >
                            {j === 0 ? (
                              <Box>
                                <CustomTextField
                                  type="date"
                                  label="Date"
                                  size="small"
                                  value={item}
                                  onChange={(e) =>
                                    handleDateChange({ event: e, day: item })
                                  }
                                  slotProps={{
                                    inputLabel: {
                                      shrink: true,
                                      style: { color: "#424242" },
                                    },
                                  }}
                                  error={dateError === item}
                                  helperText={
                                    dateError === item
                                      ? "Date already exists"
                                      : ""
                                  }
                                />
                                {specialHoursData[item].length === 1 && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) =>
                                          toggleClose({
                                            e,
                                            day: item,
                                            hoursType: "special",
                                          })
                                        }
                                        checked={!isNotClosed}
                                      />
                                    }
                                    label="Closed"
                                    sx={{
                                      "& .MuiTypography-root": {
                                        fontWeight: 400,
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                )}
                              </Box>
                            ) : specialHoursData[item].length > 1 && j === 1 ? (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      toggleClose({
                                        e,
                                        day: item,
                                        hoursType: "special",
                                      })
                                    }
                                    checked={!isNotClosed}
                                  />
                                }
                                label="Closed"
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontWeight: 400,
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            ) : (
                              <Box></Box>
                            )}
                          </TableCell>
                          {!is24Hours && isNotClosed ? (
                            <>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  p: 0.1,
                                }}
                              >
                                <Select
                                  label="Opens at"
                                  value={`${String(time?.openTime?.hours == 24 ? 0 : time?.openTime?.hours).padStart(2, "0")}:${time?.openTime?.minutes ? time?.openTime?.minutes : "00"}`}
                                  slotProps={{
                                    inputLabel: {
                                      shrink: true,
                                      style: { color: "#424242" },
                                    },
                                  }}
                                  sx={{ minWidth: 120 }}
                                  onChange={(e) => {
                                    onChangeTime({
                                      e,
                                      day: item,
                                      index: j,
                                      type: "open",
                                      hoursType: "special",
                                    });
                                  }}
                                >
                                  {hoursOptions.map((hour) => (
                                    <MenuItem
                                      key={hour.id}
                                      value={hour.time}
                                      sx={{ fontWeight: 400 }}
                                    >
                                      {hour.time}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  p: 0.1,
                                }}
                              >
                                <Select
                                  label="Closes at"
                                  value={`${String(time?.closeTime?.hours == 24 ? 0 : time?.closeTime?.hours).padStart(2, "0")}:${time?.closeTime?.minutes ? time?.closeTime?.minutes : "00"}`}
                                  slotProps={{
                                    inputLabel: {
                                      shrink: true,
                                      style: { color: "#424242" },
                                    },
                                  }}
                                  sx={{ minWidth: 120 }}
                                  onChange={(e) => {
                                    onChangeTime({
                                      e,
                                      day: item,
                                      index: j,
                                      type: "close",
                                      hoursType: "special",
                                    });
                                  }}
                                >
                                  {hoursOptions.map((hour) => (
                                    <MenuItem
                                      key={hour.id}
                                      value={hour.time}
                                      sx={{ fontWeight: 400 }}
                                    >
                                      {hour.time}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>
                            </>
                          ) : is24Hours ? (
                            <TableCell sx={{ borderBottom: "none", p: 0.1 }}>
                              <Select
                                label="Opens at"
                                value={"24 hours"}
                                slotProps={{
                                  inputLabel: {
                                    shrink: true,
                                    style: { color: "#424242" },
                                  },
                                }}
                                sx={{ minWidth: 120 }}
                                onChange={(e) => {
                                  onChangeTime({
                                    e,
                                    day: item,
                                    index: j,
                                    type: "open",
                                    hoursType: "special",
                                  });
                                }}
                              >
                                {hoursOptions.map((hour) => (
                                  <MenuItem
                                    key={hour.id}
                                    value={hour.time}
                                    sx={{ fontWeight: 400 }}
                                  >
                                    {hour.time}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                          ) : null}
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              p: 0.1,
                            }}
                          >
                            {j === 0 ? (
                              isNotClosed ? (
                                <>
                                  <IconButton
                                    id="special-hours-button"
                                    //   aria-controls={
                                    //     open ? "special-hours-menu" : undefined
                                    //   }
                                    //   aria-haspopup="true"
                                    //   aria-expanded={open ? "true" : undefined}
                                    onClick={(e) =>
                                      handleSpecialHoursActionClick(item, e)
                                    }
                                    sx={{ pt: 2 }}
                                  >
                                    <img
                                      src={dropdown}
                                      alt="dropdown"
                                      width={20}
                                      height={20}
                                    />
                                  </IconButton>
                                  <Menu
                                    id="special-hours-menu"
                                    anchorEl={specialHoursAction}
                                    open={open}
                                    onClose={handleSpecialHoursActionClose}
                                    MenuListProps={{
                                      "aria-labelledby": "special-hours-button",
                                    }}
                                  >
                                    <MenuItem
                                      value="add"
                                      onClick={() => {
                                        addTime({
                                          day: item,
                                          type: "special",
                                        });
                                        handleSpecialHoursActionClose();
                                      }}
                                    >
                                      <ListItemIcon>
                                        <img
                                          src={plus}
                                          alt="add"
                                          width={20}
                                          height={20}
                                        />
                                      </ListItemIcon>
                                      <Typography
                                        variant="body2"
                                        color="textDisabled"
                                        sx={{ py: 1 }}
                                      >
                                        Add hours
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value="delete"
                                      onClick={() => {
                                        deleteDay();
                                        handleSpecialHoursActionClose();
                                      }}
                                    >
                                      <ListItemIcon>
                                        <img
                                          src={bin}
                                          alt="bin"
                                          width={20}
                                          height={20}
                                        />
                                      </ListItemIcon>
                                      <Typography
                                        variant="body2"
                                        color="textDisabled"
                                        sx={{ py: 1 }}
                                      >
                                        Remove day
                                      </Typography>
                                    </MenuItem>
                                  </Menu>
                                </>
                              ) : null
                            ) : (
                              <IconButton
                                onClick={() =>
                                  deleteTime({
                                    day: item,
                                    index: j,
                                    type: "special",
                                  })
                                }
                              >
                                <img
                                  src={bin}
                                  alt="bin"
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Button
        startIcon={<img src={bluePlus} alt="plus" />}
        sx={{ color: "#1650CF", border: "none", my: 3 }}
        onClick={addDay}
      >
        Add a date
      </Button>
      {moreHoursData && (
        <>
          {moreHoursData?.map((data: { hoursTypeId: string; periods: any }) => (
            <Box
              key={data?.hoursTypeId}
              sx={(theme) => ({
                borderTop: `1px solid ${theme.palette.grey["200"]}`,
              })}
            >
              <Typography variant="body2" sx={{ py: 1, fontWeight: 500 }}>
                {data?.hoursTypeId[0]?.toUpperCase() +
                  data?.hoursTypeId.slice(1)?.toLowerCase()}
              </Typography>
              <Typography variant="body2" sx={{ pb: 2 }}>
                Add hours for a typical week.
              </Typography>
              <TableContainer sx={{ maxWidth: 490 }}>
                <Table sx={{ border: "none" }}>
                  <TableBody>
                    {Object.keys(data?.periods)?.map((item: any, i: number) => {
                      return (
                        <React.Fragment key={i}>
                          {data?.periods[item]?.map((time: any, j: number) => {
                            const isNotClosed =
                              time?.openTime?.hours ||
                              time?.openTime?.minutes ||
                              time?.closeTime?.hours ||
                              time?.closeTime?.minutes;
                            const is24Hours =
                              !time?.openTime?.hours &&
                              !time?.openTime?.minutes &&
                              time?.closeTime?.hours == 24 &&
                              !time?.closeTime?.minutes;

                            return (
                              <TableRow key={j}>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    p: 0.1,
                                    width: 180,
                                    py: 1,
                                  }}
                                >
                                  {j === 0 ? (
                                    <Box>
                                      <Typography variant="body2">
                                        {time?.openDay}
                                      </Typography>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={(e) =>
                                              toggleClose({
                                                e,
                                                day: item,
                                                hoursType: "more",
                                                hoursTypeId: data?.hoursTypeId,
                                              })
                                            }
                                            checked={!isNotClosed}
                                          />
                                        }
                                        label="Closed"
                                        sx={{
                                          "& .MuiTypography-root": {
                                            fontWeight: 400,
                                            fontSize: "14px",
                                          },
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    <Box></Box>
                                  )}
                                </TableCell>
                                {!is24Hours && isNotClosed ? (
                                  <>
                                    <TableCell
                                      sx={{ borderBottom: "none", p: 0.1 }}
                                    >
                                      <Select
                                        label="Opens at"
                                        value={`${String(time?.openTime?.hours == 24 ? 0 : time?.openTime?.hours).padStart(2, "0")}:${time?.openTime?.minutes ? time?.openTime?.minutes : "00"}`}
                                        slotProps={{
                                          inputLabel: {
                                            shrink: true,
                                            style: { color: "#424242" },
                                          },
                                        }}
                                        sx={{ minWidth: 120 }}
                                        onChange={(e) => {
                                          onChangeTime({
                                            e,
                                            day: item,
                                            index: j,
                                            type: "open",
                                            hoursType: "more",
                                            hoursTypeId: data?.hoursTypeId,
                                          });
                                        }}
                                      >
                                        {hoursOptions.map((hour) => (
                                          <MenuItem
                                            key={hour.id}
                                            value={hour.time}
                                            sx={{ fontWeight: 400 }}
                                          >
                                            {hour.time}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </TableCell>
                                    <TableCell
                                      sx={{ borderBottom: "none", p: 0.1 }}
                                    >
                                      <Select
                                        label="Closes at"
                                        value={`${String(time?.closeTime?.hours == 24 ? 0 : time?.closeTime?.hours).padStart(2, "0")}:${time?.closeTime?.minutes ? time?.closeTime?.minutes : "00"}`}
                                        slotProps={{
                                          inputLabel: {
                                            shrink: true,
                                            style: { color: "#424242" },
                                          },
                                        }}
                                        sx={{ minWidth: 120 }}
                                        onChange={(e) => {
                                          onChangeTime({
                                            e,
                                            day: item,
                                            index: j,
                                            type: "close",
                                            hoursType: "more",
                                            hoursTypeId: data?.hoursTypeId,
                                          });
                                        }}
                                      >
                                        {hoursOptions.map((hour) => (
                                          <MenuItem
                                            key={hour.id}
                                            value={hour.time}
                                            sx={{ fontWeight: 400 }}
                                          >
                                            {hour.time}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </TableCell>
                                    <TableCell
                                      sx={{ borderBottom: "none", p: 0.1 }}
                                    >
                                      {j === 0 ? (
                                        time?.openTime?.hours == 0 ||
                                        time?.openTime?.hours ||
                                        time?.openTime?.minutes == 0 ||
                                        time?.openTime?.minutes ? (
                                          <IconButton
                                            onClick={() =>
                                              addTime({
                                                day: item,
                                                type: "more",
                                                hoursTypeId: data?.hoursTypeId,
                                              })
                                            }
                                          >
                                            <img
                                              src={plus}
                                              alt="plus"
                                              width={24}
                                              height={24}
                                            />
                                          </IconButton>
                                        ) : null
                                      ) : (
                                        <IconButton
                                          onClick={() =>
                                            deleteTime({
                                              day: item,
                                              index: j,
                                              type: "more",
                                              hoursTypeId: data?.hoursTypeId,
                                            })
                                          }
                                        >
                                          <img
                                            src={bin}
                                            alt="bin"
                                            width={24}
                                            height={24}
                                          />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  </>
                                ) : is24Hours ? (
                                  <TableCell
                                    sx={{ borderBottom: "none", p: 0.1 }}
                                  >
                                    <Select
                                      label="Opens at"
                                      value={"24 hours"}
                                      slotProps={{
                                        inputLabel: {
                                          shrink: true,
                                          style: { color: "#424242" },
                                        },
                                      }}
                                      sx={{ minWidth: 120 }}
                                      onChange={(e) => {
                                        onChangeTime({
                                          e,
                                          day: item,
                                          index: j,
                                          type: "open",
                                          hoursType: "more",
                                          hoursTypeId: data?.hoursTypeId,
                                        });
                                      }}
                                    >
                                      {hoursOptions.map((hour) => (
                                        <MenuItem
                                          key={hour.id}
                                          value={hour.time}
                                          sx={{ fontWeight: 400 }}
                                        >
                                          {hour.time}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </>
      )}
      <Divider sx={{ my: 1 }} />
      <Typography variant="body2" sx={{ py: 2 }}>
        Add more hours
      </Typography>
      <Box gap={1} sx={{ display: "flex", flexWrap: "wrap" }}>
        {hoursList?.map((hour: any, index: number) => (
          <Chip
            key={index}
            label={hour?.name}
            avatar={
              <img
                src={plusBlue}
                alt="plus"
                style={{ width: 12, height: 12 }}
              />
            }
            onClick={() => addMoreHour({ hour })}
            sx={(theme) => ({
              fontSize: "14px",
              fontWeight: 500,
              backgroundColor: "transparent",
              border: `1px solid ${theme.palette.grey["500"]}`,
              cursor: "pointer",
            })}
          />
        ))}
      </Box>
    </Box>
  );
};

export const Map: React.FC<{
  formData: any;
  updateFormData: ({ key, value }: { key: string; value: any }) => void;
}> = ({ formData, updateFormData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data } = useSelector((state: RootState) => state.bulkActions);
  const [statesList, setStatesList] = useState<any>([]);
  const [showAddress, setShowAddress] = useState(
    Boolean(formData?.store_address)
  );
  const [addAddressLine, setAddAddressLine] = useState(true);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(getStatesList());
  }, []);

  useEffect(() => {
    setStatesList(data?.statesList?.states);
  }, [data.statesList]);

  useEffect(() => {
    setAddAddressLine(
      formData?.store_address?.addressLines[
        formData?.store_address?.addressLines?.length - 1
      ] !== "" || formData?.store_address?.addressLines?.length < 3
    );
  }, [formData?.store_address?.addressLines]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = async (searchQuery: string) => {
    const geocoder = new window.google.maps.Geocoder();

    try {
      const response: any = await new Promise((resolve, reject) => {
        geocoder.geocode({ address: searchQuery }, (results, status) => {
          if (status === "OK" && results && results[0]) {
            resolve(results[0].geometry.location);
          } else {
            reject("No results found or geocoding error");
          }
        });
      });
      updateFormData({
        key: "mapCenter",
        value: {
          lat: response.lat(),
          lng: response.lng(),
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  console.log(formData?.mapCenter?.lat);
  return (
    <Box>
      <Typography variant="body2" color="textDisabled">
        If customers visit your business, add an address and adjust the pin on
        the map to its location.
      </Typography>
      <FormControlLabel
        control={<Toggle onChange={(e) => setShowAddress(e.target.checked)} />}
        label="Show business address to customers"
        checked={showAddress}
        sx={{
          "& .MuiTypography-root": { fontWeight: 400, fontSize: "14px", ml: 1 },
          m: 2,
          ml: 0,
        }}
      />
      {showAddress && (
        <Box gap={2} sx={{ display: "flex" }}>
          <Box width="100%">
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Country/Region
            </Typography>
            <Box
              sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}
            >
              <CustomTextField
                variant="filled"
                sx={{
                  mb: 1,
                  "& .MuiInputBase-root": { height: 40, borderRadius: 0.5 },
                }}
                value={formData?.country}
                disabled
                fullWidth
              />
              <IconButton id="info" onClick={handleClick}>
                <img src={info} alt="info" />
              </IconButton>
              <Menu
                id="info-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "info",
                }}
              >
                <Box sx={{ p: 1, maxWidth: 300 }}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    This can't be edited
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    To change your country / region, you'll have to create a new
                    Business Profile on Google.
                  </Typography>
                </Box>
              </Menu>
            </Box>
            {formData?.store_address?.addressLines?.map(
              (address: any, index: number) => (
                <Box key={index}>
                  <Typography variant="caption" sx={{ fontWeight: 500 }}>
                    Street Address{" "}
                    {index !== 0 ? `${index + 1} (optional)` : ""}
                  </Typography>
                  <CustomTextField
                    variant="filled"
                    sx={{
                      mb: 1,
                      "& .MuiInputBase-root": { height: 40, borderRadius: 0.5 },
                    }}
                    value={address}
                    fullWidth
                    onChange={(e) => {
                      updateFormData({
                        key: "store_address",
                        value: {
                          ...formData?.store_address,
                          addressLines:
                            formData?.store_address?.addressLines?.map(
                              (val: any, ind: number) => {
                                if (ind === index) {
                                  return e.target.value;
                                }
                                return val;
                              }
                            ),
                        },
                      });
                      //   setPlaceDetails({
                      //     ...placeDetails,
                      //     streetAddress: [
                      //       ...placeDetails.streetAddress.map(
                      //         (val: any, ind: number) => {
                      //           if (ind === index) {
                      //             return e.target.value;
                      //           }
                      //           return val;
                      //         }
                      //       ),
                      //     ],
                      //   })
                    }}
                  />
                </Box>
              )
            )}
            {addAddressLine && (
              <>
                <ButtonOutline
                  sx={{ border: "none", fontSize: "12px" }}
                  startIcon={<img src={plusBlue} alt="plus" />}
                  onClick={() => {
                    updateFormData({
                      key: "store_address",
                      value: {
                        ...formData.store_address,
                        addressLines: [
                          ...formData.store_address.addressLines,
                          "",
                        ],
                      },
                    });
                  }}
                >
                  Add address line (optional)
                </ButtonOutline>
                <br />
              </>
            )}
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Town/City
            </Typography>
            <CustomTextField
              variant="filled"
              sx={{
                mb: 1,
                "& .MuiInputBase-root": { height: 40, borderRadius: 0.5 },
              }}
              value={formData?.city}
              onChange={(e) =>
                updateFormData({ key: "city", value: e.target.value })
              }
              fullWidth
            />
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              State
            </Typography>
            {statesList && (
              <CustomTextField
                select
                fullWidth
                value={formData?.state}
                slotProps={{
                  select: {
                    IconComponent: (props) => (
                      <DownIcon
                        {...props}
                        style={{ width: 20, fontWeight: 100 }}
                      />
                    ),
                  },
                }}
                variant="filled"
                size="small"
                sx={{
                  ml: 0,
                  mb: 0,
                  backgroundColor: "#f8f1f182",
                  border: "none",
                  height: 40,
                }}
                onChange={(e) => {
                  updateFormData({ key: "state", value: e.target.value });
                  handleSearch(`${e.target.value}, India`);
                }}
              >
                {statesList?.map((state: any) => (
                  <MenuItem
                    key={state.state_id}
                    sx={{ fontWeight: 400, fontSize: "14px" }}
                    value={state.state_name}
                  >
                    {state.state_name}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          </Box>
          <Box width="100%">
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: 250 }}
                center={{
                  lat: isNaN(formData?.mapCenter?.lat)
                    ? 0
                    : parseFloat(formData?.mapCenter?.lat),
                  lng: isNaN(formData?.mapCenter?.lng)
                    ? 0
                    : parseFloat(formData?.mapCenter?.lng),
                }}
                zoom={10}
              >
                <Marker
                  position={{
                    lat: isNaN(formData?.mapCenter?.lat)
                      ? 0
                      : parseFloat(formData?.mapCenter?.lat),
                    lng: isNaN(formData?.mapCenter?.lng)
                      ? 0
                      : parseFloat(formData?.mapCenter?.lng),
                  }}
                />
              </GoogleMap>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
