import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { FromProps } from "interfaces/BusinessProfiles.types";
import { getBulkActionListing } from "store/bulkActionslistingDetailsSlice";
import { getListing } from "store/listingDetailsSlice";
import apiManager from "utils/apiManager";

interface GroupState {
  groups: any[] | null;
  masterGroup: string | null;
  count: number | null;
  loading: boolean;
  groupsAction: { success: boolean; message: string } | null;
  error: string | null;
}

const initialState: GroupState = {
  groups: null,
  masterGroup: "All",
  count: null,
  loading: false,
  groupsAction: null,
  error: null,
};

export const createGroup = createAsyncThunk(
  "/create-group",
  async ({ label }: { label: string }, thunkAPI) => {
    try {
      const response = await apiManager.post("/create-group", { label });
      if (response) thunkAPI.dispatch(getGroups());
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create group"
      );
    }
  }
);

export const removeGroup = createAsyncThunk(
  "/remove-group",
  async (
    {
      from,
      req,
    }: { from: FromProps; req: { location_id: string; group_id: string } },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.delete("/remove-group", { data: req });
      thunkAPI.dispatch(
        from === "individual"
          ? getListing(req.location_id)
          : getBulkActionListing(req.location_id)
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to remove group"
      );
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "/delete-group",
  async ({ group_id }: { group_id: string }, thunkAPI) => {
    try {
      const response = await apiManager.delete("/delete-group", {
        data: { group_id },
      });
      thunkAPI.dispatch(getGroups());
      return response.data.message;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete group"
      );
    }
  }
);

export const renameGroup = createAsyncThunk(
  "/edit-group",
  async (req: { group_name: string; group_id: string }, thunkAPI) => {
    try {
      const response = await apiManager.patch("/edit-group", req);
      if (response) thunkAPI.dispatch(getGroups());
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to rename group"
      );
    }
  }
);

export const getGroups = createAsyncThunk(
  "fetch-group",
  async (_, thunkAPI) => {
    try {
      const response = await apiManager.get(`/fetch-group`);
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to get groups"
      );
    }
  }
);

export const groupMapping = createAsyncThunk(
  "/create-group-mapping",
  async (
    {
      from,
      req,
    }: {
      from: FromProps;
      req: {
        location_id: string;
        group_id: number;
      };
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/create-group-mapping", req);
      thunkAPI.dispatch(
        from === "individual"
          ? getListing(req.location_id)
          : getBulkActionListing(req.location_id)
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to map location to the group"
      );
    }
  }
);

const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setMasterGroup: (state, action: PayloadAction<string>) => {
      state.masterGroup = action.payload;
    },
    resetGroupsAction: (state) => {
      state.groupsAction = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //create group
      .addCase(createGroup.pending, (state) => {
        state.loading = true;
        state.groupsAction = null;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.groupsAction = {
          success: true,
          message: action.payload.message as string,
        };
      })
      .addCase(createGroup.rejected, (state, action: any) => {
        state.loading = false;
        state.groupsAction = {
          success: false,
          message: action.payload as string,
        };
      })
      //get groups
      .addCase(getGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.groupsAction = null;
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.loading = false;
        state.error = null;
        state.groupsAction = null;
      })
      .addCase(getGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.groupsAction = null;
      })
      //group mapping
      .addCase(groupMapping.pending, (state) => {
        state.loading = true;
        state.groupsAction = null;
      })
      .addCase(groupMapping.fulfilled, (state, action) => {
        state.loading = false;
        state.groupsAction = {
          success: true,
          message: action.payload.message as string,
        };
      })
      .addCase(groupMapping.rejected, (state, action: any) => {
        state.loading = false;
        state.groupsAction = {
          success: false,
          message: action.payload as string,
        };
      })
      //remove group
      .addCase(removeGroup.pending, (state) => {
        state.loading = true;
        state.groupsAction = null;
      })
      .addCase(removeGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.groupsAction = {
          success: true,
          message: action.payload.message as string,
        };
      })
      .addCase(removeGroup.rejected, (state, action: any) => {
        state.loading = false;
        state.groupsAction = {
          success: false,
          message: action.payload as string,
        };
      })
      //rename group
      .addCase(renameGroup.pending, (state) => {
        state.loading = true;
        state.groupsAction = null;
      })
      .addCase(renameGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.groupsAction = {
          success: true,
          message: action.payload as string,
        };
      })
      .addCase(renameGroup.rejected, (state, action: any) => {
        state.loading = false;
        state.groupsAction = {
          success: false,
          message: action.payload as string,
        };
      })
      //delete group
      .addCase(deleteGroup.pending, (state) => {
        state.loading = true;
        state.groupsAction = null;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.groupsAction = {
          success: true,
          message: action.payload as string,
        };
      })
      .addCase(deleteGroup.rejected, (state, action: any) => {
        state.loading = false;
        state.groupsAction = {
          success: false,
          message: action.payload as string,
        };
      });
  },
});
export const { setMasterGroup, resetGroupsAction } = groupSlice.actions;
export default groupSlice.reducer;
