import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "App.css";
import { theme } from "utils/theme";
import protectedRoutesList from "routes/protectedRoutes";
import nonProtectedRoutesList from "routes/nonProtectedRoutes";
import MainLayout from "layouts/MainLayout.tsx";
import AuthLayout from "layouts/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "store";
import { useEffect } from "react";
import { setupApiManager } from "utils/apiManager";
import { getGroups } from "store/groupSlice";

function App() {
  const { token } = useSelector((state: RootState) => state.user);
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setupApiManager(store.getState, dispatch);
    dispatch(getGroups());
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            {nonProtectedRoutesList.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  token ? (
                    <Navigate to="/insights" />
                  ) : (
                    <AuthLayout>
                      <route.component />
                    </AuthLayout>
                  )
                }
              />
            ))}
            {protectedRoutesList.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  !token ? (
                    <Navigate to="/login" />
                  ) : route.emptylayout ? (
                    <route.component />
                  ) : (
                    <MainLayout
                      content={
                        !user?.connected &&
                        ![
                          "/insights",
                          "/settings",
                          "api/google/callback",
                        ].includes(route.path) ? (
                          <Navigate to="/insights" />
                        ) : (
                          <route.component />
                        )
                      }
                    />
                  )
                }
              />
            ))}
            <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
