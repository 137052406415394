import { Avatar, Box, Grid2, Typography } from "@mui/material";
import React from "react";

import { flexRowCenterCenter } from "utils/styles";
import CircularProgressWithBuffer from "components/CircularProgressWithBuffer";
import { ButtonOutline } from "components/Buttons";
import description from "assets/icons/description.svg";
import passport from "assets/icons/passport.svg";
import coverPhoto from "assets/icons/cover-photo.svg";
import photo from "assets/icons/photo.svg";
import videoOn from "assets/icons/video-on.svg";
import fileEdit from "assets/icons/file-edit.svg";
import arrowCurveLeftUp from "assets/icons/arrow-curve-left-up.svg";
import folder from "assets/icons/folder.svg";
import folderPlus from "assets/icons/folder-plus.svg";
import star from "assets/icons/star.svg";
import settingsBlack from "assets/icons/settings-black.svg";

const cardsData = [
  {
    title: "Poor",
    value: 300,
    progress: 40,
    score: 94.2,
    min: 1,
    max: 59,
    color: "#F2B9AD",
  },
  {
    title: "Moderate",
    value: 24,
    progress: 65,
    score: 14.2,
    min: 60,
    max: 84,
    color: "#8FA3FA",
  },
  {
    title: "Good",
    value: 13,
    progress: 75,
    score: 8.2,
    min: 85,
    max: 100,
    color: "#1BC2CF",
  },
];

interface BoosterItem {
  name: string;
  description: string;
  action: string;
  actionLabel: string;
  icon: any;
}

const engagementBooster: BoosterItem[] = [
  {
    name: "Description",
    description:
      "100 have descriptions that are shorter than the optimal length of 225 characters",
    action: "update",
    actionLabel: "Update",
    icon: <img src={description} alt="description" width="19.2px" />,
  },
  {
    name: "Posts",
    description:
      "983 have descriptions that are shorter than the optimal length of 225 characters",
    action: "update",
    actionLabel: "Update",
    icon: <img src={passport} alt="posts" sizes="24px" />,
  },
  {
    name: "Cover Photo",
    description: "Cover photo 50 profiles are Missing",
    action: "upload",
    actionLabel: "Upload",
    icon: <img src={coverPhoto} alt="cover-photo" sizes="24px" />,
  },
  {
    name: "Additional Photos",
    description: "983 profiles require at least 1 additional photo",
    action: "upload",
    actionLabel: "Upload",
    icon: <img src={photo} alt="photo" sizes="24px" />,
  },
  {
    name: "Interior Photo",
    description: "983 profiles require interior photo",
    action: "upload",
    actionLabel: "Upload",
    icon: <img src={photo} alt="photo" sizes="24px" />,
  },
  {
    name: "Videos",
    description: "983 profiles require at least 2 videos",
    action: "upload",
    actionLabel: "Upload",
    icon: <img src={videoOn} alt="video-on" sizes="24px" />,
  },
];

const performanceBooster: BoosterItem[] = [
  {
    name: "Ratings",
    description: "74 profiles have an average rating below 3",
    action: "view",
    actionLabel: "View Profiles",
    icon: <img src={star} alt="star" sizes="24px" />,
  },
  {
    name: "Reviews Generation",
    description:
      "983 profiles have generated less than 3 reviews in the last 7 days",
    action: "learn",
    actionLabel: "Learn More",
    icon: <img src={arrowCurveLeftUp} alt="reviews-generation" sizes="24px" />,
  },
  {
    name: "Reviews Response",
    description: "6 Reviews in 17 profiles are not replied yet",
    action: "reply",
    actionLabel: "Reply",
    icon: <img src={fileEdit} alt="file-edit" sizes="24px" />,
  },
  {
    name: "Services",
    description: "Services of 983 profiles are missing",
    action: "reply",
    actionLabel: "Reply",
    icon: <img src={settingsBlack} alt="settings" sizes="24px" />,
  },
  {
    name: "Secondary Categories",
    description: "983 profiles require at least 3 secondary categories",
    action: "reply",
    actionLabel: "Reply",
    icon: <img src={folder} alt="folder" sizes="24px" />,
  },
  {
    name: "Additional Categories",
    description: "8 profiles require at least 2 additional categories",
    action: "reply",
    actionLabel: "Reply",
    icon: <img src={folderPlus} alt="additional-categories" sizes="24px" />,
  },
];
const Profile = () => {
  return (
    <Box sx={{ width: "100%", textAlign: "left" }}>
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
        gap={3}
      >
        {cardsData?.map((data, index) => (
          <Box
            key={index}
            sx={{
              p: 3,
              border: "1px solid #d0d5dd",
              borderRadius: "8px",
              boxSizing: "border-box",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}
            >
              <Typography variant="h5">{data?.title}</Typography>
              <Typography variant="body2" color="textDisabled">
                View
              </Typography>
            </Box>
            <Box
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h2">{data?.value}</Typography>
                <Typography variant="body2" color="textDisabled">
                  <strong style={{ color: "#1D1E20" }}>{data?.score}%</strong>{" "}
                  score {data?.min}% - {data?.max}%
                </Typography>
              </Box>
              <CircularProgressWithBuffer
                progress={data?.progress}
                color={data?.color}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box pt={3}>
        <Grid2 container spacing={3}>
          <BoosterCard
            boosterData={engagementBooster}
            title="Engagement Booster"
          />
          <BoosterCard
            boosterData={performanceBooster}
            title="Performance Booster"
          />
        </Grid2>
      </Box>
    </Box>
  );
};

export default Profile;

const BoosterCard: React.FC<{ boosterData: BoosterItem[]; title: string }> = ({
  boosterData,
  title,
}) => (
  <Grid2
    size={{ xs: 12, md: 6 }}
    p={3}
    border={1}
    borderColor="grey.300"
    borderRadius={2}
  >
    <Typography variant="h5" gutterBottom pb={3}>
      {title}
    </Typography>
    {boosterData.map((item, index) => (
      <Box
        key={index}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Avatar
          alt={item.name}
          sx={(theme) => ({
            width: 64,
            height: 64,
            mr: 2,
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.grey["300"]}`,
          })}
        >
          {item.icon}
        </Avatar>
        <Box flexGrow={1} pr={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {item.name}
          </Typography>
          <Typography variant="body2" color="textDisabled">
            {item.description}
          </Typography>
        </Box>
        <ButtonOutline
          sx={{
            minWidth: 124,
            whiteSpace: "nowrap",
          }}
        >
          {item.actionLabel}
        </ButtonOutline>
      </Box>
    ))}
  </Grid2>
);
