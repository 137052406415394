import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getListing } from "store/listingDetailsSlice";
import { getListings } from "store/listingsSlice";
import apiManager from "utils/apiManager";

interface BulkActionsState {
  count: number | null;
  loading: boolean;
  status: { success: boolean; message: string } | null;
  data: {
    hoursList: any;
    statesList: any;
    categoriesList: any;
    productsList: any;
  };
  dataLoading: boolean;
  dataStatus: { success: boolean; message: string } | null;
}

const initialState: BulkActionsState = {
  count: null,
  loading: false,
  status: null,
  data: {
    hoursList: null,
    statesList: null,
    categoriesList: null,
    productsList: null,
  },
  dataLoading: false,
  dataStatus: null,
};

export const createPost = createAsyncThunk(
  "/create-post",
  async (req: FormData, thunkAPI) => {
    try {
      const response = await apiManager.post("/create-post", req, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create post"
      );
    }
  }
);

export const createLogoPost = createAsyncThunk(
  "/create-logo-post",
  async (
    { req, location_id }: { req: FormData; location_id: string },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/create-logo-post", req, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response) {
        const { dispatch } = thunkAPI;
        dispatch(getListing(location_id));
      }
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const locationUpdate = createAsyncThunk(
  "/location-update",
  async (
    req: {
      location_id: string;
      title: string;
      phoneNumbers?: string;
      additionalPhone?: string[];
      web_site: string;
      description: string;
      regularHours: any;
      specialHours?: any;
      moreHour?: any;
      openingDate: any;
      openInfo?: string;
      storefrontAddress?: any;
      primaryCategory?: string | number;
      addcategories?: any;
      latlng?: { latitude?: any; longitude?: any };
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.put("/location-update", req);
      if (response) {
        const { dispatch } = thunkAPI;
        dispatch(getListing(req?.location_id));
      }
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const bulkLocationUpdate = createAsyncThunk(
  "/bulk-location-update",
  async (
    req: {
      location_id: string;
      title?: string;
      phoneNumbers?: string;
      additionalPhone?: string[];
      web_site?: string;
      description?: string;
      regularHours?: any;
      specialHours?: any;
      moreHour?: any;
      openingDate: any;
      openInfo?: string;
      storefrontAddress?: any;
      primaryCategory?: string | number;
      latlng?: { latitude: any; longitude: any };
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.put("/bulk-location-update", req);
      if (response) {
        const { dispatch } = thunkAPI;
        dispatch(getListings({ req: { page: 0, limit: 10 } }));
      }
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const locationAttributesUpdate = createAsyncThunk(
  "/location-attributes-update",
  async (
    req: {
      location_id: string[];
      attributes: {
        name: string;
        values: boolean;
      }[];
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.put("/location-attributes-update", req);
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Attributes were not updated"
      );
    }
  }
);

export const getHoursList = createAsyncThunk(
  "/hours-list",
  async (_, thunkAPI) => {
    try {
      const response = await apiManager.get("/hours-list");
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const getStatesList = createAsyncThunk(
  "/state-list",
  async (_, thunkAPI) => {
    try {
      const response = await apiManager.post("/state-list");
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const getCategoriesList = createAsyncThunk(
  "/location-category",
  async (_, thunkAPI) => {
    try {
      const response = await apiManager.get("/location-category");
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const getProductsList = createAsyncThunk(
  "/product-list",
  async (
    {
      id,
      filter,
      page,
      limit,
    }: { id?: string; filter: string; page: number; limit: number },
    thunkAPI
  ) => {
    console.log(id);
    try {
      const response = await apiManager.get(
        `/product-list?page=${page}&limit=${limit}&filter=${filter}`
      );
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const createProduct = createAsyncThunk(
  "/create-product",
  async (req: FormData, thunkAPI) => {
    try {
      const response = await apiManager.post("/create-product", req, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const editProduct = createAsyncThunk(
  "/edit-product",
  async (req: FormData, thunkAPI) => {
    try {
      const response = await apiManager.patch("/edit-product", req, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "/delete-product",
  async (product_id: string, thunkAPI) => {
    try {
      const response = await apiManager.delete(`/delete-product/${product_id}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to remove group"
      );
    }
  }
);

const bulkActionsSlice = createSlice({
  name: "bulkActions",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //create post
      .addCase(createPost.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.loading = false;
        state.status = { success: true, message: action.payload };
      })
      .addCase(createPost.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      })
      //create logo post
      .addCase(createLogoPost.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(createLogoPost.fulfilled, (state, action) => {
        state.loading = false;
        state.status = { success: true, message: action.payload };
      })
      .addCase(createLogoPost.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      })
      // get hours list
      .addCase(getHoursList.pending, (state) => {
        state.dataLoading = true;
        state.data.hoursList = null;
        state.dataStatus = null;
      })
      .addCase(getHoursList.fulfilled, (state, action) => {
        state.dataLoading = false;
        state.data.hoursList = action.payload;
        state.dataStatus = { success: true, message: action.payload };
      })
      .addCase(getHoursList.rejected, (state, action) => {
        state.dataLoading = false;
        state.data.hoursList = null;
        state.dataStatus = { success: true, message: action.payload as string };
      })
      // get states list
      .addCase(getStatesList.pending, (state) => {
        state.dataLoading = true;
        state.data.statesList = null;
        state.dataStatus = null;
      })
      .addCase(getStatesList.fulfilled, (state, action) => {
        state.dataLoading = false;
        state.data.statesList = action.payload;
        state.dataStatus = { success: true, message: action.payload };
      })
      .addCase(getStatesList.rejected, (state, action) => {
        state.dataLoading = false;
        state.data.statesList = null;
        state.dataStatus = { success: true, message: action.payload as string };
      })
      // get categories list
      .addCase(getCategoriesList.pending, (state) => {
        state.dataLoading = true;
        state.data.categoriesList = null;
        state.dataStatus = null;
      })
      .addCase(getCategoriesList.fulfilled, (state, action) => {
        state.dataLoading = false;
        state.data.categoriesList = action.payload.data;
        state.dataStatus = {
          success: action.payload.success,
          message: action.payload.message,
        };
      })
      .addCase(getCategoriesList.rejected, (state, action) => {
        state.dataLoading = false;
        state.data.categoriesList = null;
        state.dataStatus = { success: true, message: action.payload as string };
      })
      //location update
      .addCase(locationUpdate.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(locationUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.status = { success: true, message: action.payload };
      })
      .addCase(locationUpdate.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      })
      // bulk location update
      .addCase(bulkLocationUpdate.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(bulkLocationUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.status = {
          success: action.payload.success,
          message: action.payload.message,
        };
      })
      .addCase(bulkLocationUpdate.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      })
      //location attributes update
      .addCase(locationAttributesUpdate.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(locationAttributesUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.status = { success: true, message: action.payload };
      })
      .addCase(locationAttributesUpdate.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      })
      // get products list
      .addCase(getProductsList.pending, (state) => {
        state.dataLoading = true;
        state.data.productsList = null;
        state.dataStatus = null;
      })
      .addCase(getProductsList.fulfilled, (state, action) => {
        state.dataLoading = false;
        state.data.productsList = action.payload;
        state.dataStatus = { success: true, message: action.payload };
      })
      .addCase(getProductsList.rejected, (state, action) => {
        state.dataLoading = false;
        state.data.productsList = null;
        state.dataStatus = { success: true, message: action.payload as string };
      })
      //create product
      .addCase(createProduct.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.status = {
          success: action.payload.success,
          message: action.payload.message,
        };
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      })
      //edit product
      .addCase(editProduct.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.status = {
          success: action.payload.success,
          message: action.payload.message,
        };
      })
      .addCase(editProduct.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      })
      //delete product
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.status = {
          success: action.payload.success,
          message: action.payload.success
            ? action.payload.message
            : action.payload.message.error.message,
        };
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      });
  },
});

export const { resetStatus } = bulkActionsSlice.actions;

export default bulkActionsSlice.reducer;
