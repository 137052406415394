import BulkUploadTracker from "pages/BulkUploadTracker";
import BusinessProfiles from "pages/BusinessProfiles";
import BusinessProfile from "pages/BusinessProfiles/BusinessProfile";
import Contacts from "pages/Contacts";
import DuplicatedListings from "pages/DuplicatedListings";
import Login from "pages/Insights";
import Settings from "pages/Settings";
import QA from "pages/QA";
import RankTracker from "pages/RankTracker";
import Reviews from "pages/Reviews";
import SwitchTenant from "pages/SwitchTenant";
import UserManagement from "pages/UserManagement";
import GoogleLogin from "pages/GoogleLogin";

const protectedRoutes = [
  {
    path: "/insights",
    component: Login,
  },
  {
    path: "/reviews",
    component: Reviews,
  },
  {
    path: "/contacts",
    component: Contacts,
  },
  {
    path: "/duplicated-listings",
    component: DuplicatedListings,
  },

  {
    path: "/rank-tracker",
    component: RankTracker,
  },
  {
    path: "/bulk-upload-tracker",
    component: BulkUploadTracker,
  },

  {
    path: "/qa",
    component: QA,
  },
  {
    path: "/business-profiles",
    component: BusinessProfiles,
  },
  {
    path: "/business-profile/:id",
    component: BusinessProfile,
  },
  {
    path: "/user-management",
    component: UserManagement,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/switch-tenant",
    component: SwitchTenant,
  },
  {
    path: "/api/google/callback",
    component: GoogleLogin,
    emptylayout: true,
  },
];

export default protectedRoutes;
