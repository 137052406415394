import { Avatar, Box, Button, Chip, Grid2, Typography } from "@mui/material";
import CircularProgressWithBuffer from "components/CircularProgressWithBuffer";
import { BorderLinearProgress } from "pages/Insights/Components";
import React from "react";
import { flexColumnCenterCenter, flexRowCenterCenter } from "utils/styles";
import ArrowLongUp from "assets/icons/arrowLongUp";
import passport from "assets/icons/passport.svg";
import fileEdit from "assets/icons/file-edit.svg";
import star from "assets/icons/star.svg";
import photo from "assets/icons/photo.svg";
import settings from "assets/icons/settings-black.svg";

const profileReachesData = [
  { parameter: "Accounts Reached", value: 95.3 },
  { parameter: "Accounts Engaged", value: 80 },
  { parameter: "Content you shared", value: 0 },
  { parameter: "Accounts Reached", value: 95.3 },
  { parameter: "Profile Status", value: 35 },
];

const boosterPerformanceData = [
  { title: "Posts", icon: passport, value: 200, increment: 15 },
  { title: "Reviews", icon: fileEdit, value: 50, increment: 5 },
  { title: "Ratings", icon: star, value: 500, increment: 5 },
  { title: "Photos", icon: photo, value: 80, increment: 5 },
  { title: "Services", icon: settings, value: 40, increment: 5 },
];

const ProfileBooster = () => {
  return (
    <Box width="100%" sx={{ textAlign: "left" }}>
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, lg: 5 }}>
          <Box
            gap={5}
            sx={{
              position: "relative",
              width: "100%",
              margin: "auto",
              height: "100%",
              border: "1px solid #E6E6E6",
              borderRadius: "8px",
              p: "28px 31px",
              boxSizing: "border-box",
              ...flexRowCenterCenter,
            }}
          >
            <Box width="30%" sx={{ textAlign: "center" }}>
              <Typography sx={{ fontSize: "72px", pb: 1 }}>65%</Typography>
              <Typography variant="body1">
                of your profile is complete
              </Typography>
            </Box>
            <Box width="70%">
              <Typography variant="h3" sx={{ pb: 2 }}>
                Profile Progress
              </Typography>
              <Typography variant="body2" color="textDisabled">
                Business Profile resulted in increased visibility in local
                search results, higher engagement rates from users, and a boost
                in customer interactions.{" "}
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={65}
                bgcolor="#FFC565"
                sx={{ my: 2 }}
              />
              <Button
                variant="contained"
                sx={{ backgroundColor: "#036672", border: "none", height: 40 }}
              >
                Complete Profile
              </Button>
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 3.5 }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              margin: "auto",
              height: "100%",
              border: "1px solid #E6E6E6",
              borderRadius: "8px",
              p: "35px 24px 27px",
              boxSizing: "border-box",
            }}
          >
            <Typography variant="h3" sx={{ pb: 2 }}>
              Profile Reaches
            </Typography>
            {profileReachesData.map((data, index) => (
              <Box
                key={index}
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "space-between",
                  pb: 2,
                }}
              >
                <Typography variant="body2" color="textDisabled">
                  {data.parameter}
                </Typography>
                <Typography variant="body2" color="textDisabled">
                  {data.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 3.5 }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              margin: "auto",
              height: "100%",
              border: "1px solid #E6E6E6",
              borderRadius: "8px",
              p: "33px 24px",
              boxSizing: "border-box",
            }}
          >
            <Typography variant="h3" sx={{ pb: 2 }}>
              Booster Performance
            </Typography>
            <Typography
              variant="body2"
              color="textDisabled"
              sx={{ pt: 1, pb: 2 }}
            >
              Plan upgrade to boost your profile and to access premium features
              and enhanced support
            </Typography>
            <Box
              sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}
            >
              <Box>
                <Typography variant="h1">10,401</Typography>
                <Typography variant="body2" sx={{ pt: "5px" }}>
                  Accounts Reached
                </Typography>
              </Box>
              <Box>
                <CircularProgressWithBuffer progress={45} radius={40} />
                <Typography variant="body2">Overall Progress</Typography>
              </Box>
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Box
            sx={{
              p: "51px 40px 85px",
              border: "1px solid #E6E6E6",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h3">
              Top 5 Profile Booster Performance
            </Typography>
            <Typography variant="body2" color="textDisabled">
              The updated Google Business Profile resulted in increased
              visibility in local search results, higher engagement rates from
              users, and a boost in customer interactions.{" "}
            </Typography>
            <Box
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {boosterPerformanceData.map((data, index) => (
                <Box key={index} pt={6}>
                  <Avatar
                    sx={(theme) => ({
                      width: 142,
                      height: 142,
                      backgroundColor: theme.palette.grey["100"],
                    })}
                  >
                    <Box sx={flexColumnCenterCenter}>
                      <img
                        src={data.icon}
                        alt={data.title}
                        width="56px"
                        height="56px"
                      />
                      <Typography variant="h3" color="primary">
                        {data.value}
                      </Typography>
                    </Box>
                  </Avatar>
                  <Box
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "space-between",
                      pt: 2,
                    }}
                  >
                    <Typography variant="h6">{data.title}</Typography>
                    <Chip
                      sx={(theme) => ({
                        color: theme.palette.success.main,
                        backgroundColor: "#E8F6ED",
                      })}
                      icon={<ArrowLongUp color="#137A5F" />}
                      label={`${data.increment}%`}
                    />
                  </Box>
                  <BorderLinearProgress
                    variant="determinate"
                    value={data.increment}
                    bgcolor="#38CAD5"
                    sx={{ my: 2 }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ProfileBooster;
