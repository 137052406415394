import { initialWorkingHoursData } from "pages/BusinessProfiles/Modals/Bulkactions/Profile";

export const getHealthStatus = (value: number) => {
  switch (true) {
    case value <= 80:
      return "Poor";
    case value >= 81 && value <= 85:
      return "Good";
    case value > 85:
      return "Excellent";
    default:
      return "";
  }
};

export const getRatingInt = (value: string) => {
  switch (value) {
    case "ONE":
      return 1;
    case "TWO":
      return 2;
    case "THREE":
      return 3;
    case "FOUR":
      return 4;
    case "FIVE":
      return 5;
    default:
      return null;
  }
};

export const getRatingString = (value: string) => {
  switch (value) {
    case "1":
      return "ONE";
    case "2":
      return "TWO";
    case "3":
      return "THREE";
    case "4":
      return "FOUR";
    case "5":
      return "FIVE";
    default:
      return null;
  }
};

export const hoursArrayToObject = (hours: any) => {
  const newWorkingHours = hours?.reduce((acc: any, item: any) => {
    const day = item.openDay;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  const updatedWorkingHours = Object.keys(initialWorkingHoursData).reduce(
    (acc: any, week: string) => {
      if (newWorkingHours[week]) {
        acc[week] = newWorkingHours[week];
      } else {
        acc[week] =
          initialWorkingHoursData[week as keyof typeof initialWorkingHoursData];
      }
      return acc;
    },
    {} as Record<string, any>
  );
  return updatedWorkingHours;
};

export const transformHours = (hours: any) => {
  return Object.values(hours || {})
    .flat()
    .filter((data: any) => {
      const { openTime, closeTime } = data;
      return !(
        (openTime.hours === 0 || openTime.hours === undefined) &&
        (openTime.minutes === 0 || openTime.minutes === undefined) &&
        (closeTime.hours === 0 || closeTime.hours === undefined) &&
        (closeTime.minutes === 0 || closeTime.minutes === undefined)
      );
    });
};

export const convertAttributes = (data: any) => {
  const result: any = [];
  data?.forEach((section: any) => {
    section.options.forEach((option: any) => {
      // If the option has a valid id and selectedValue is either null or true
      if (option.id && option.selectedValue !== null) {
        result.push({
          name: option.id,
          values:
            option.selectedValue === "Yes"
              ? true
              : option.selectedValue === "No"
                ? false
                : null,
        });
      }
    });
  });
  return result;
};

export const imageUrlToFile = async (url: string): Promise<File> => {
  const urlObject = new URL(url);
  const pathname = urlObject.pathname;
  const fileName = pathname.split("/").pop();
  const filename = decodeURIComponent(fileName!);
  const res = await fetch(url);
  const blob = await res.blob();
  const file = new File([blob], filename, { type: blob.type });

  return file;
};
